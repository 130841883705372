import { $$contactEditPage } from './contact-edit-page';
import { contact } from '@/shared/api';
import { $$alerts } from '@/shared/effector';
import { myMoment } from '@/shared/tools';
import { attach, createEvent, createStore, sample } from 'effector';
import { reset, spread } from 'patronum';
var updateFx = attach({ effect: contact.update });
var $contactId = createStore(null);
var $firstName = createStore('');
var $lastName = createStore('');
var $phone = createStore('');
var $telegramId = createStore('');
var $profession = createStore('');
var $birthDate = createStore('');
var $comment = createStore('');
var $country = createStore('');
var $city = createStore('');
var $pending = updateFx.pending;
var $error = createStore(null);
var firstNameChanged = createEvent();
var lastNameChanged = createEvent();
var phoneChanged = createEvent();
var telegramIdChanged = createEvent();
var professionChanged = createEvent();
var birthDateChanged = createEvent();
var commentChanged = createEvent();
var countryChanged = createEvent();
var cityChanged = createEvent();
var inited = createEvent();
var submited = createEvent();
var reseted = createEvent();
$lastName.on(lastNameChanged, function (_, value) { return value; });
$firstName.on(firstNameChanged, function (_, value) { return value; });
$phone.on(phoneChanged, function (_, value) { return value; });
$telegramId.on(telegramIdChanged, function (_, value) { return value; });
$profession.on(professionChanged, function (_, value) { return value; });
$birthDate.on(birthDateChanged, function (_, value) {
    return value ? myMoment(value).format('YYYY-MM-DD') : myMoment().format('YYYY-MM-DD');
});
$comment.on(commentChanged, function (_, value) { return value; });
$country.on(countryChanged, function (_, value) { return value; });
$city.on(cityChanged, function (_, value) { return value; });
// Inited
sample({
    clock: $$contactEditPage.$contact.updates,
    filter: function (contact) { return !!contact; },
    fn: function (contact) { return contact; },
    target: inited,
});
sample({
    clock: inited,
    target: spread({
        id: $contactId,
        surname: $lastName,
        name: $firstName,
        telephone: $phone,
        telegram: $telegramId,
        profession: $profession,
        birthDate: birthDateChanged,
        note: $comment,
        country: $country,
        city: $city,
    }),
});
// Submited
sample({
    clock: submited,
    source: {
        contact_id: $contactId,
        last_name: $lastName,
        first_name: $firstName,
        phone: $phone,
        telegram_ID: $telegramId,
        profession: $profession,
        birth_date: $birthDate,
        comment: $comment,
        country: $country,
        city: $city,
    },
    filter: function (_a) {
        var contact_id = _a.contact_id;
        return !!contact_id;
    },
    fn: function (contact) { return contact; },
    target: updateFx,
});
// Done
sample({
    clock: updateFx.done,
    fn: function () { return 'Успешно сохранено'; },
    target: $$alerts.showSuccess,
});
sample({
    clock: updateFx.done,
    target: $$contactEditPage.reload,
});
// Reseted
reset({
    clock: reseted,
    target: [
        $contactId,
        $lastName,
        $firstName,
        $phone,
        $telegramId,
        $profession,
        $birthDate,
        $comment,
        $country,
        $city,
    ],
});
reset({
    clock: [
        lastNameChanged,
        firstNameChanged,
        phoneChanged,
        telegramIdChanged,
        professionChanged,
        birthDateChanged,
        commentChanged,
        countryChanged,
        cityChanged,
    ],
    target: $error,
});
export var $$contactEditForm = {
    $contactId: $contactId,
    $lastName: $lastName,
    $firstName: $firstName,
    $phone: $phone,
    $telegramId: $telegramId,
    $profession: $profession,
    $birthDate: $birthDate,
    $comment: $comment,
    $country: $country,
    $city: $city,
    $error: $error,
    $pending: $pending,
    lastNameChanged: lastNameChanged,
    firstNameChanged: firstNameChanged,
    phoneChanged: phoneChanged,
    telegramIdChanged: telegramIdChanged,
    professionChanged: professionChanged,
    birthDateChanged: birthDateChanged,
    commentChanged: commentChanged,
    countryChanged: countryChanged,
    cityChanged: cityChanged,
    submited: submited,
    reseted: reseted,
};
