import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
//import { SkeletonYourPotentialIncome } from './skeleton'
import './style.scss';
import { MemoActiveStrategySelect } from '@/features/strategy';
import { MemoButton, PageLayout } from '@/shared/components';
import { $$PotentialIncome, $$activeStrategy, $$basicStrategy } from '@/shared/effector';
import { $$ModalIncomeCalculation } from '@/shared/effector/finance/modal-income-calculation';
import { useUnit } from 'effector-react';
import { useEffect, useLayoutEffect } from 'react';
export var YourPotentialIncome = function () {
    var _a = useUnit([
        $$ModalIncomeCalculation.openModal,
        $$PotentialIncome.$spacedValue,
    ]), openModal = _a[0], spacedValue = _a[1];
    var _b = useUnit([
        $$basicStrategy.getAll.$items,
        $$basicStrategy.getAll.submited,
    ]), items = _b[0], submited = _b[1];
    var strategyId = useUnit([$$activeStrategy.$activeStrategyId])[0];
    var strategyIdChanged = useUnit([$$PotentialIncome.strategyIdChanged])[0];
    useLayoutEffect(function () { return submited(); }, [submited]);
    useEffect(function () {
        strategyIdChanged(strategyId);
    }, [strategyId, strategyIdChanged]);
    // Render
    //if (pending) {
    //  return <SkeletonYourPotentialIncome />
    //}
    return (_jsxs(PageLayout.Cloud, { contentClassName: "your-potential-income", header: {
            title: 'Ваш потенциальный доход',
            subtitle: 'Покажи на что ты способен!',
            right: _jsx(MemoActiveStrategySelect, { className: "your-potential-income__select" }),
        }, children: [_jsxs("div", { className: "your-potential-income__text", children: [_jsxs("div", { className: "your-potential-income__value", children: ["$ ", spacedValue] }), _jsx("div", { className: "your-potential-income__title", children: "\u041F\u043E\u0442\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u044B\u0439 \u0434\u043E\u0445\u043E\u0434" })] }), _jsx("div", { className: "your-potential-income__buttons", children: _jsx(MemoButton, { onClick: openModal, children: "\u041F\u043E\u043B\u043D\u044B\u0439 \u0440\u0430\u0441\u0447\u0435\u0442 \u043F\u043E\u0442\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u0434\u043E\u0445\u043E\u0434\u0430" }) })] }));
};
