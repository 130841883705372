import { contact } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { GetAllFactory } from '@/shared/factories';
import { sample } from 'effector';
//* Factories
var getAll = GetAllFactory({
    name: 'CONTACT',
    api: contact.get,
});
//* Logic
sample({
    clock: $$session.authed,
    target: getAll.submited,
});
export var $$contacts = getAll;
