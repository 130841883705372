import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MemoFooter, MemoGoBackButton, MemoManagement } from './components';
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var AuthLayout = function (_a) {
    var className = _a.className, children = _a.children;
    // Variables
    var ClassName = classNames('auth-layout', className);
    return (_jsxs("div", { className: ClassName, children: [_jsxs("div", { className: "auth-layout__main", children: [_jsx("div", { className: "auth-layout__header", children: _jsx(MemoGoBackButton, {}) }), _jsx("div", { className: "auth-layout__content", children: children }), _jsx(MemoFooter, { className: "auth-layout__footer" })] }), _jsx("div", { className: "auth-layout__aside", children: _jsx(MemoManagement, {}) })] }));
};
export var MemoAuthLayout = memo(AuthLayout);
