import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Общий шаблон для модалки 
import { $$contactCommunication } from '../../model';
import { MemoCommunicationView } from '../communication-view';
import { MemoResponseView } from '../response-view';
import './style.scss';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
export var CommunicationModal = function (_a) {
    var className = _a.className, show = _a.show, onHide = _a.onHide;
    // Effector
    var _b = useUnit([
        $$contactCommunication.submited,
        $$contactCommunication.form.reseted,
    ]), submit = _b[0], reseted = _b[1];
    // State
    var _c = useState('communication'), activeView = _c[0], setActiveView = _c[1];
    // Variables
    var ClassName = classNames('communication-modal', className);
    // Handler
    var handleHide = useCallback(function () {
        setActiveView('communication');
        reseted();
        onHide();
    }, [onHide, reseted]);
    var handleSubmitCommunicationView = useCallback(function () { return setActiveView('response'); }, []);
    var handleSubmitResponseView = useCallback(function () {
        submit();
        handleHide();
    }, [handleHide, submit]);
    // Render
    var renderCommunication = function () { return (_jsx(MemoCommunicationView, { onSubmit: handleSubmitCommunicationView })); };
    var renderResponse = function () { return _jsx(MemoResponseView, { onSubmit: handleSubmitResponseView }); };
    return (_jsxs(Modal, { className: ClassName, show: show, onHide: handleHide, centered: true, children: [activeView === 'communication' && renderCommunication(), activeView === 'response' && renderResponse()] }));
};
export var MemoCommunicationModal = memo(CommunicationModal);
