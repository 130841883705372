import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { $$contactCommunication } from '../../model';
import { SkeletonActiveStatus } from './skeleton';
import './style.scss';
import { useContactStatus } from '@/entities/contact';
import { $$globalStrategyStatusColor } from '@/shared/effector';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo } from 'react';
export var ActiveStatus = function (_a) {
    var className = _a.className;
    // Effector
    var _b = useUnit([
        $$contactCommunication.info.$contact,
        $$contactCommunication.info.$infoPending,
    ]), contact = _b[0], infoPending = _b[1];
    var _c = useContactStatus(contact === null || contact === void 0 ? void 0 : contact.contact_id), status = _c.status, statusPending = _c.pending;
    var _d = useUnit([
        $$globalStrategyStatusColor.getAll.$dictionaryById,
        $$globalStrategyStatusColor.getAll.$status,
    ]), colorsDictionary = _d[0], colorsStatus = _d[1];
    // Variables
    var ClassName = classNames('communication-active-status', className);
    var color = (status === null || status === void 0 ? void 0 : status.global_strategy_status_color_id) in colorsDictionary
        ? colorsDictionary[status.global_strategy_status_color_id]
        : undefined;
    var pending = infoPending || statusPending || colorsStatus === 'pending';
    // Render
    if (pending) {
        return _jsx(SkeletonActiveStatus, {});
    }
    return (_jsxs("div", { className: ClassName, children: [_jsx("div", { className: "communication-active-status__indicator", style: { color: color } }), _jsx("div", { className: "communication-active-status__name", children: status === null || status === void 0 ? void 0 : status.name })] }));
};
export var MemoActiveStatus = memo(ActiveStatus);
