import { landing } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getFx = attach({ effect: landing.get });
var $item = createStore(null);
var $pending = getFx.pending;
var submited = createEvent();
var reload = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: [submited, reload],
    target: getFx,
});
// Done
sample({
    clock: getFx.doneData,
    target: $item,
});
export var $$myLanding = {
    $item: $item,
    $pending: $pending,
    submited: submited,
    reload: reload,
    done: getFx.done,
};
