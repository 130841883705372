import { contact } from '@/shared/api';
import { $$alerts, $$contactCount, $$contacts, $$lastContact } from '@/shared/effector';
import { attach, createEvent, sample } from 'effector';
var createFx = attach({ effect: contact.create });
var $pending = createFx.pending;
var submited = createEvent();
// Submited
sample({
    clock: submited,
    target: createFx,
});
// Done
sample({
    clock: createFx.done,
    fn: function () { return 'Контакты успешно импортированы'; },
    target: $$alerts.showSuccess,
});
sample({
    clock: createFx.done,
    target: $$contactCount.submited,
});
sample({
    clock: createFx.done,
    target: $$contacts.submited,
});
sample({
    clock: createFx.done,
    target: $$lastContact.submited,
});
export var $$importContacts = {
    $pending: $pending,
    submited: submited,
    done: createFx.done,
    fail: createFx.fail,
};
