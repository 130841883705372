import { request } from './request';
import { createEffect } from 'effector';
var get = createEffect(function () { return request('landings/', { method: 'GET' }); });
var getByLogin = createEffect(function (_a) {
    var login = _a.login;
    return request("landings/".concat(login), { method: 'GET' });
});
var update = createEffect(function (data) {
    return request("landings/", { method: 'PUT', data: data });
});
export var landing = {
    get: get,
    getByLogin: getByLogin,
    update: update,
};
