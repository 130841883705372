import { currentRoute } from './model';
import { suspenseWrapper } from '@/pages/suspense-wrapper';
import { PageLayout } from '@/shared/components';
import { lazy } from 'react';
export var Page = suspenseWrapper(lazy(function () { return import('./ui'); }));
export var ContactItemsRoute = {
    view: Page,
    route: currentRoute,
    layout: PageLayout,
};
