import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, MemoButton } from '@/shared/components';
import { $$contacts } from '@/shared/effector';
import { useUnit } from 'effector-react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { utils, writeFile } from 'xlsx';
export var ExportContactsButton = function () {
    // Effector
    var contacts = useUnit($$contacts.$items);
    // State
    var _a = useState(false), show = _a[0], setShow = _a[1];
    // Handlers
    var handleChange = function () {
        if (contacts.length === 0) {
            return;
        }
        var worksheet = utils.json_to_sheet(contacts);
        var workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Contacts');
        writeFile(workbook, 'contacts.xlsx');
        setShow(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(MemoButton, { onClick: function () { return setShow(true); }, children: "\u042D\u043A\u0441\u043F\u043E\u0440\u0442" }), _jsxs(Modal, { show: show, onHide: function () { return setShow(false); }, children: [_jsx(Modal.Header, { children: _jsx(Modal.Title, { children: "\u042D\u043A\u0441\u043F\u043E\u0440\u0442 \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u043E\u0432" }) }), _jsxs(Modal.Body, { children: ["\u041D\u0430\u0436\u043C\u0438\u0442\u0435 \u043A\u043D\u043E\u043F\u043A\u0443 \u043D\u0438\u0436\u0435, \u0447\u0442\u043E\u0431\u044B \u044D\u043A\u0441\u043F\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u044B \u0432 \u0444\u0430\u0439\u043B Excel ", _jsx("b", { children: "(.xlsx)" }), "."] }), _jsxs(Modal.Footer, { children: [_jsx(Button, { variant: "primary", onClick: handleChange, children: "\u042D\u043A\u0441\u043F\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }), _jsx(Button, { variant: "secondary", onClick: function () { return setShow(false); }, children: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C" })] })] })] }));
};
