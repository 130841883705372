import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row } from './components';
import { Head } from './components/head';
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var Table = function (_a) {
    var className = _a.className, keyField = _a.keyField, columns = _a.columns, data = _a.data, onItemClick = _a.onItemClick, onItemEdit = _a.onItemEdit;
    // Variables
    var ClassName = classNames('my-table', className);
    return (_jsxs("div", { className: ClassName, children: [_jsx(Head, { columns: columns, showEdit: !!onItemEdit }), data.map(function (item) { return (_jsx(Row, { columns: columns, entity: item, onClick: onItemClick, onEdit: onItemEdit }, item[keyField])); })] }));
};
export var MemoTable = memo(Table);
