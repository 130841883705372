/* eslint-disable @typescript-eslint/no-explicit-any */
import { createEffect, createEvent, createStore, sample } from 'effector';
import { debug, reset, status } from 'patronum';
export function RequestFactory(_a) {
    var name = _a.name, api = _a.api, useDebug = _a.useDebug;
    var getName = function (unit) { return "".concat(name, " ").concat(unit); };
    //* API
    var apiFx = createEffect(getName('api-fx'), { handler: api });
    //* Stores
    var $params = createStore(null, { name: getName('params') });
    var $status = status(apiFx);
    var $wasLoaded = createStore(false, { name: getName('was-loaded') });
    //* Events
    var submited = createEvent(getName('submited'));
    var reloaded = createEvent(getName('reloaded'));
    var done = apiFx.doneData;
    var fail = apiFx.failData;
    var reseted = createEvent();
    //* Logic
    // Reseted
    reset({
        clock: reseted,
        target: [$params, $wasLoaded],
    });
    // Submited
    $wasLoaded.on(submited, function () { return true; });
    sample({
        clock: submited,
        filter: function (params) { return !!params; },
        target: $params,
    });
    sample({
        clock: submited,
        source: $status,
        filter: function (status) { return status !== 'pending'; },
        fn: function (_, params) { return params; },
        target: apiFx,
    });
    // Reload
    sample({
        clock: reloaded,
        source: { params: $params, status: $status },
        filter: function (_a) {
            var params = _a.params, status = _a.status;
            return !!params && status !== 'pending';
        },
        fn: function (_a) {
            var params = _a.params;
            return params;
        },
        target: apiFx,
    });
    if (useDebug) {
        debug(apiFx, $params, submited, reloaded);
    }
    return {
        $params: $params,
        $status: $status,
        $wasLoaded: $wasLoaded,
        submited: submited,
        reloaded: reloaded,
        done: done,
        fail: fail,
        reseted: reseted,
    };
}
