var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { customStrategy } from '@/shared/api';
import { $$activeStrategy, $$alerts, $$customStrategies } from '@/shared/effector';
import { RequestFactory } from '@/shared/factories';
import { createEvent, sample } from 'effector';
var reset = RequestFactory({
    name: 'RESET_CUSTOM_STRATEGY',
    api: customStrategy.reset,
});
var submited = createEvent();
sample({
    clock: submited,
    source: $$activeStrategy.$strategy,
    target: reset.submited,
});
sample({
    clock: reset.done,
    fn: function () { return 'Стратегия успешно сброшена'; },
    target: $$alerts.showSuccess,
});
sample({
    clock: reset.done,
    source: $$activeStrategy.$strategy,
    fn: function (strategy) { return strategy.custom_strategy_id; },
    target: [$$activeStrategy.requestes.strategy.submited, $$customStrategies.submited],
});
export var $$resetStrategyButton = __assign(__assign({}, reset), { submited: submited });
