var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { $$activeContact } from '@/entities/contact/model';
import { communication } from '@/shared/api';
import { $$activeStrategy } from '@/shared/effector';
import { GetAllFactory } from '@/shared/factories';
import { createEvent, createStore, sample } from 'effector';
import { combineEvents } from 'patronum';
//* Factories
var getCommunications = GetAllFactory({
    name: 'ACTIVE_CONTACT_COMMUNICATIONS',
    api: communication.getAllByContact,
    useDebug: true,
});
//* Stores
var $maxHeight = createStore(300);
//* Events
var submitWithContact = createEvent();
var submitWithStrategy = createEvent();
var maxHeightChanged = createEvent();
var allLoaded = combineEvents({
    events: {
        contact: $$activeContact.done,
        strategy: $$activeStrategy.requestes.strategy.done,
    },
});
//* Logic
$maxHeight.on(maxHeightChanged, function (_, value) { return value; });
sample({
    clock: allLoaded,
    fn: function (_a) {
        var contact = _a.contact, strategy = _a.strategy;
        return ({
            contact_id: contact.contact_id,
            strategy_id: strategy.custom_strategy_id,
        });
    },
    target: getCommunications.submited,
});
sample({
    clock: $$activeContact.done,
    target: submitWithContact,
});
sample({
    clock: submitWithContact,
    source: $$activeStrategy.$strategy,
    filter: function (item) { return Boolean(item); },
    fn: function (strategy, contact) { return ({
        contact_id: contact.contact_id,
        strategy_id: strategy.custom_strategy_id,
    }); },
    target: getCommunications.submited,
});
sample({
    clock: $$activeStrategy.requestes.strategy.done,
    target: submitWithStrategy,
});
sample({
    clock: submitWithStrategy,
    source: $$activeContact.$item,
    filter: function (item) { return Boolean(item); },
    fn: function (contact, strategy) { return ({
        contact_id: contact.contact_id,
        strategy_id: strategy.custom_strategy_id,
    }); },
    target: getCommunications.submited,
});
export var $$activeContactCommunications = __assign(__assign({}, getCommunications), { $maxHeight: $maxHeight, maxHeightChanged: maxHeightChanged, submitWithContact: submitWithContact });
