import { get } from './_get';
import { getLogin } from './_get-login';
import { update } from './_update';
import { updateAvatar } from './_update-avatar';
export var profile = {
    get: get,
    getLogin: getLogin,
    update: update,
    updateAvatar: updateAvatar,
};
export * from './types';
