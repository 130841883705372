import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { LevelsIcon } from '@/shared/assets';
import { LevelsIconJackpot } from '@/shared/assets';
import { useTheme } from '@/shared/theme';
import classNames from 'classnames';
import { memo } from 'react';
export var SmallWidgetLevels = function (_a) {
    var className = _a.className, index = _a.index, amount = _a.amount, quantity = _a.quantity, available = _a.available, status = _a.status;
    // Variables
    var theme = useTheme().theme;
    var ClassName = classNames('small-widget-levels', { active: available }, className);
    var TextClassName = classNames('small-widget-levels__text', { active: available });
    var IconClassName = classNames('small-widget-levels__icon', { active: available });
    return (_jsxs("div", { className: ClassName, children: [_jsxs("div", { className: IconClassName, children: [_jsx("img", { src: status === 'jackpot' ? LevelsIconJackpot : LevelsIcon, width: status === 'jackpot' ? 22.36 : 35, height: status === 'jackpot' ? 22.36 : 35 }), status !== 'jackpot' && _jsx("div", { className: "small-widget-levels__icon__index", children: index })] }), _jsxs("div", { className: TextClassName, children: [_jsxs("div", { className: "small-widget-levels__index", children: [' ', status === 'jackpot' ? 'Jackpot' : "Pool ".concat(index)] }), _jsxs("div", { className: "small-widget-levels__title", children: [amount, " $"] }), _jsxs("div", { className: "small-widget-levels__value", children: [' ', status === 'jackpot' ? '' : "Qualified:"] }), status !== 'jackpot' && (
                    //small-widget-levels__quantityContainer__dark  theme
                    _jsx("div", { className: "".concat(theme === 'light'
                            ? 'small-widget-levels__quantityContainer__light'
                            : 'small-widget-levels__quantityContainer__dark', " "), children: _jsx("div", { className: "small-widget-levels__quantity", children: quantity }) }))] })] }));
};
export var MemoSmallWidgetLevels = memo(SmallWidgetLevels);
