var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestFactory } from './request';
import { createStore, sample } from 'effector';
import { debug, reset } from 'patronum';
export function GetAllFactory(_a) {
    var name = _a.name, api = _a.api, useDebug = _a.useDebug;
    var getName = function (unit) { return "get-all ".concat(name, " ").concat(unit); };
    //* Request
    var request = RequestFactory({ name: "get-all ".concat(name), api: api, useDebug: useDebug });
    //* Stores
    var $items = createStore([], { name: getName('items') });
    //* Logic
    // Reseted
    reset({
        clock: request.reseted,
        target: [$items],
    });
    // Done
    sample({
        clock: request.done,
        target: $items,
    });
    if (useDebug) {
        debug($items);
    }
    return __assign(__assign({}, request), { $items: $items });
}
