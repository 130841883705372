import { createEvent, createStore } from 'effector';
var $pageTitle = createStore('');
var started = createEvent();
var pageTitleChanged = createEvent();
$pageTitle.on(pageTitleChanged, function (_, value) { return value; });
export var $$app = {
    $pageTitle: $pageTitle,
    started: started,
    pageTitleChanged: pageTitleChanged,
};
