import { create } from './_create';
import { getAll } from './_get-all';
import { getAllOfStrategies } from './_get-all-of-strategies';
import { update } from './_update';
export var customStrategyStep = {
    getAll: getAll,
    getAllOfStrategies: getAllOfStrategies,
    create: create,
    update: update,
};
