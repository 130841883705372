import { auth } from '@/shared/api';
import { $$alerts, $$session } from '@/shared/effector';
import { routes } from '@/shared/router';
import { redirect } from 'atomic-router';
import { attach, combine, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
var registerFx = attach({ effect: auth.register });
var $firstName = createStore('');
var $lastName = createStore('');
var $login = createStore('');
var $email = createStore('');
var $phone = createStore('');
var $password = createStore('');
var $confirmPassword = createStore('');
var $parentLogin = createStore('PRM4ALL');
var $termsReaded = createStore(true);
var $pending = registerFx.pending;
var $error = createStore('');
var $canSubmit = combine($firstName, $lastName, $login, $email, $phone, $password, $confirmPassword, $parentLogin, $termsReaded, $error, function (firstName, lastName, login, email, phone, password, confirmPassword, parentLogin, termsReaded, error) {
    var validators = [
        firstName.trim() !== '',
        lastName.trim() !== '',
        login.trim() !== '',
        email.trim() !== '',
        phone.trim() !== '',
        password.trim() !== '',
        confirmPassword.trim() !== '',
        password.trim() === confirmPassword.trim(),
        parentLogin.trim() !== '',
        termsReaded,
        !error,
    ];
    return validators.every(function (validator) { return validator; });
});
var firstNameChanged = createEvent();
var lastNameChanged = createEvent();
var loginChanged = createEvent();
var emailChanged = createEvent();
var phoneChanged = createEvent();
var passwordChanged = createEvent();
var confirmPasswordChanged = createEvent();
var parentLoginChanged = createEvent();
var termsReadedChanged = createEvent();
var opened = createEvent();
var submited = createEvent();
var reseted = createEvent();
$firstName.on(firstNameChanged, function (_, value) { return value; });
$lastName.on(lastNameChanged, function (_, value) { return value; });
$login.on(loginChanged, function (_, value) { return value; });
$email.on(emailChanged, function (_, value) { return value; });
$phone.on(phoneChanged, function (_, value) { return value; });
$password.on(passwordChanged, function (_, value) { return value; });
$confirmPassword.on(confirmPasswordChanged, function (_, value) { return value; });
$parentLogin.on(parentLoginChanged, function (_, value) { return value; });
$termsReaded.on(termsReadedChanged, function (_, value) { return value; });
// Opened
sample({
    clock: opened,
    source: $$session.$refLogin,
    target: parentLoginChanged,
});
// Submited
sample({
    clock: submited,
    source: {
        firstName: $firstName,
        lastName: $lastName,
        login: $login,
        email: $email,
        phone: $phone,
        password: $password,
        confirmPassword: $confirmPassword,
        parentLogin: $parentLogin,
    },
    target: registerFx,
});
// Done
redirect({
    clock: registerFx.done,
    route: routes.auth.login,
});
// Fali
sample({
    clock: registerFx.failData,
    target: [$error, $$alerts.showDanger],
});
// Reseted
sample({
    clock: [
        firstNameChanged,
        lastNameChanged,
        loginChanged,
        emailChanged,
        phoneChanged,
        passwordChanged,
        confirmPasswordChanged,
        parentLoginChanged,
    ],
    source: $error,
    filter: function (error) { return !!error; },
    fn: function () { return null; },
    target: $error,
});
reset({
    clock: reseted,
    target: [
        $firstName,
        $lastName,
        $login,
        $email,
        $phone,
        $password,
        $confirmPassword,
        $parentLogin,
        $error,
    ],
});
export var $$registerForm = {
    $firstName: $firstName,
    $lastName: $lastName,
    $login: $login,
    $email: $email,
    $phone: $phone,
    $password: $password,
    $confirmPassword: $confirmPassword,
    $parentLogin: $parentLogin,
    $termsReaded: $termsReaded,
    $pending: $pending,
    $error: $error,
    $canSubmit: $canSubmit,
    firstNameChanged: firstNameChanged,
    lastNameChanged: lastNameChanged,
    loginChanged: loginChanged,
    emailChanged: emailChanged,
    phoneChanged: phoneChanged,
    passwordChanged: passwordChanged,
    confirmPasswordChanged: confirmPasswordChanged,
    parentLoginChanged: parentLoginChanged,
    termsReadedChanged: termsReadedChanged,
    opened: opened,
    reseted: reseted,
    submited: submited,
    done: registerFx.done,
    fail: registerFx.fail,
};
