import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { LikeFillIcon, LikeOutlineIcon } from '@/shared/assets';
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
export var LikeStrategyButton = function (_a) {
    var className = _a.className;
    // State
    var _b = useState(false), liked = _b[0], setLiked = _b[1];
    // Variables
    var ClassName = classNames('like-strategy-button', className);
    var Icon = liked ? LikeFillIcon : LikeOutlineIcon;
    // Handlers
    var handleClick = useCallback(function () { return setLiked(function (prev) { return !prev; }); }, []);
    return (_jsx("div", { className: ClassName, onClick: handleClick, children: _jsx(Icon, { width: 32, height: 32 }) }));
};
export var MemoLikeStrategyButton = memo(LikeStrategyButton);
