import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { $$app } from '@/shared/effector';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo } from 'react';
export var PageTitle = function (_a) {
    var className = _a.className;
    // Effector
    var title = useUnit($$app.$pageTitle);
    // Variables
    var ClassName = classNames('page-title', className);
    return _jsx("div", { className: ClassName, children: title });
};
export var MemoPageTitle = memo(PageTitle);
