import { communication, contact } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, restore, sample } from 'effector';
var getLastFx = attach({ effect: contact.getLast });
var getLastContactCommunicationsFx = attach({ effect: communication.getAllByContactIdFx });
var $item = createStore(null);
var $communications = restore(getLastContactCommunicationsFx, []);
var $pending = getLastFx.pending;
var submited = createEvent();
sample({
    clock: $item.updates,
    source: $item,
    fn: function (item) { return item.contact_id; },
    target: getLastContactCommunicationsFx,
});
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    target: getLastFx,
});
// Done
sample({
    clock: getLastFx.doneData,
    target: $item,
});
export var $$lastContact = {
    $item: $item,
    $pending: $pending,
    submited: submited,
    done: getLastFx.done,
    $communications: $communications,
};
