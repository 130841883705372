import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
import { Spinner } from 'react-bootstrap';
export var Loader = function (_a) {
    var className = _a.className;
    // Variables
    var ClassName = classNames('loader', className);
    return (_jsx("div", { className: ClassName, children: _jsx(Spinner, {}) }));
};
export var MemoLoader = memo(Loader);
