import { contact } from '@/shared/api';
import { attach, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
var getItemFx = attach({ effect: contact.getItem });
var $contact = createStore(null);
var $pending = getItemFx.pending;
var submited = createEvent();
var reload = createEvent();
var reseted = createEvent();
// Submited
sample({
    clock: submited,
    fn: function (id) { return ({ id: id }); },
    target: getItemFx,
});
// Reload
sample({
    clock: reload,
    source: $contact,
    filter: function (contact) { return !!contact; },
    fn: function (contact) { return ({ id: contact.contact_id }); },
    target: getItemFx,
});
// Done
sample({
    clock: getItemFx.doneData,
    target: $contact,
});
// Resetes
reset({
    clock: reseted,
    target: [$contact],
});
export var $$contactEditPage = {
    $contact: $contact,
    $pending: $pending,
    submited: submited,
    reload: reload,
    reseted: reseted,
    done: getItemFx.done,
    doneData: getItemFx.doneData,
};
