import { request } from './request';
import { createEffect } from 'effector';
var register = createEffect(function (data) {
    return request('auth/register', { method: 'POST', data: data });
});
var login = createEffect(function (data) {
    return request('auth/login', { method: 'POST', data: data });
});
export var auth = {
    register: register,
    login: login,
};
