import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var Row = function (_a) {
    var className = _a.className, columns = _a.columns, entity = _a.entity, onClick = _a.onClick, onEdit = _a.onEdit;
    // Variables
    var ClassName = classNames('table-row', { 'table-row--clickable': onClick }, className);
    // Utils
    var getValue = function (_a) {
        var name = _a.name, compute = _a.compute;
        if (compute) {
            return compute(entity);
        }
        if (typeof entity === 'object' && entity && Object.keys(entity).includes(name)) {
            return entity[name];
        }
        return '-';
    };
    // Handler
    var handleClick = function () {
        onClick === null || onClick === void 0 ? void 0 : onClick(entity);
    };
    var handleEdit = function (event) {
        event.stopPropagation();
        onEdit === null || onEdit === void 0 ? void 0 : onEdit(entity);
    };
    return (_jsxs("div", { className: ClassName, onClick: handleClick, children: [columns.map(function (column, index) { return (_jsx("div", { className: "table-row__item", children: getValue(column) }, index)); }), onEdit && (_jsx("div", { className: "table-row__item table-row__item--edit", onClick: handleEdit, children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }))] }));
};
export var MemoRow = memo(Row);
