import { request } from './request';
import { createEffect } from 'effector';
var getBalance = createEffect(function () {
    return request('finances/balance', { method: 'GET' });
});
var getHistory = createEffect(function () {
    return request('finances/history', { method: 'GET' });
});
var postCalculateIncomes = createEffect(function (_a) {
    var custom_strategy_id = _a.custom_strategy_id;
    return request('finances/calculate-incomes', {
        method: 'POST',
        data: { custom_strategy_id: custom_strategy_id },
    });
});
var getReports = createEffect(function (_a) {
    var custom_strategy_id = _a.custom_strategy_id, startDate = _a.startDate, endDate = _a.endDate;
    return request("reports/incomes?custom_strategy_id=".concat(custom_strategy_id, "&start_date=").concat(startDate, "&end_date=").concat(endDate), { method: 'GET' });
});
// withdraw
var withdraw = createEffect(function (_a) {
    var total = _a.total;
    return request('finances/withdraw', {
        method: 'POST',
        data: { total: total },
    });
});
export var finance = {
    getBalance: getBalance,
    getHistory: getHistory,
    postCalculateIncomes: postCalculateIncomes,
    getReports: getReports,
    withdraw: withdraw
};
