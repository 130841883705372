import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { MemoSkeletonButton, PageLayout } from '@/shared/components';
import Skeleton from 'react-loading-skeleton';
export var SkeletonYourPotentialIncome = function () {
    return (_jsxs(PageLayout.Cloud, { contentClassName: "your-potential-income", header: {
            title: 'Ваш потенциальный доход',
            subtitle: 'Покажи на что ты способен!',
        }, children: [_jsxs("div", { className: "your-potential-income__text", children: [_jsx(Skeleton, { className: "your-potential-income__value", width: 200 }), _jsx(Skeleton, { className: "your-potential-income__title", width: 120 })] }), _jsx("div", { className: "your-potential-income__buttons", children: _jsx(MemoSkeletonButton, {}) })] }));
};
