var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { contact } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { GetOneFactory } from '@/shared/factories';
import { sample } from 'effector';
//* Factories
var _a = GetOneFactory({
    name: 'CONTACTS_COUNT',
    api: contact.getCount,
}), $item = _a.$item, getOne = __rest(_a, ["$item"]);
//* Stores
var $value = $item.map(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.count) !== null && _a !== void 0 ? _a : 0; });
//* Logic
sample({
    clock: $$session.authed,
    target: getOne.submited,
});
export var $$contactsCount = __assign(__assign({}, getOne), { $value: $value });
