import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo, useCallback, useId } from 'react';
import { Form } from 'react-bootstrap';
export var ColorPicker = function (_a) {
    var className = _a.className, label = _a.label, caption = _a.caption, _b = _a.size, size = _b === void 0 ? 'lg' : _b, value = _a.value, onChange = _a.onChange;
    // Hooks
    var id = useId();
    // Variables
    var ClassName = classNames('color-picker', className);
    // Handlers
    var handleChange = useCallback(function (event) {
        onChange === null || onChange === void 0 ? void 0 : onChange(event.target.value);
    }, [onChange]);
    return (_jsxs(Form.Group, { className: ClassName, controlId: id, children: [label && _jsx(Form.Label, { children: label }), _jsx(Form.Control, { id: id, type: "color", size: size, defaultValue: "#563d7c", value: value || '', onChange: handleChange }), caption && _jsx(Form.Text, { children: caption })] }));
};
export var MemoColorPicker = memo(ColorPicker);
