import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Первая модалка - Форма связи
import { $$contactCommunication } from '../../model';
import './style.scss';
import { MemoButton } from '@/shared/components';
import { $$alerts, $$communicationType } from '@/shared/effector';
import { useCopy } from '@/shared/hooks';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo, useCallback } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useHideHtmlTags } from '@/shared/hooks/use-hide-htmlTags';
export var CommunicationView = function (_a) {
    var className = _a.className, onSubmit = _a.onSubmit;
    // Effector
    var showAlert = useUnit($$alerts.showSuccess);
    var step = useUnit([$$contactCommunication.info.$nextStep])[0];
    var _b = useUnit([
        $$contactCommunication.form.$communicationTypeId,
        $$contactCommunication.form.communicationTypeIdChanged,
    ]), communicationTypeId = _b[0], communicationTypeIdChanged = _b[1];
    var communicationTypes = useUnit([$$communicationType.getAll.$items])[0];
    // Hooks
    var onCopy = useCopy(step.description).onCopy;
    // Variables
    var ClassName = classNames('communication-view', className);
    // Handlers
    var handleCopy = useCallback(function () {
        onCopy();
        showAlert('Скопировано');
    }, [onCopy, showAlert]);
    var handleChange = useCallback(function (event) {
        communicationTypeIdChanged(parseInt(event.target.value));
    }, [communicationTypeIdChanged]);
    return (_jsxs("div", { className: ClassName, children: [_jsx(Modal.Header, { closeButton: true, children: _jsx(Modal.Title, { children: "\u0424\u043E\u0440\u043C\u0430 \u0441\u0432\u044F\u0437\u0438" }) }), _jsxs(Modal.Body, { children: [_jsxs("div", { className: "communication-view__section", children: [_jsx("div", { className: "communication-view__section-header", children: "\u0421\u043F\u043E\u0441\u043E\u0431 \u0441\u0432\u044F\u0437\u0438" }), communicationTypes.map(function (communication) { return (_jsx(Form.Check, { type: "radio", id: "choose-communication-type-".concat(communication.communication_type_id), label: communication.name, value: communication.communication_type_id, checked: communication.communication_type_id === communicationTypeId, onChange: handleChange }, communication.communication_type_id)); })] }), _jsxs("div", { className: "communication-view__section", children: [_jsx("div", { className: "communication-view__section-header", children: "\u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435" }), useHideHtmlTags(step.description), _jsx("div", { className: "communication-view__section-footer", children: _jsx(MemoButton, { onClick: handleCopy, children: "\u0421\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }) })] })] }), _jsx(Modal.Footer, { children: _jsx(MemoButton, { disabled: !communicationTypeId, onClick: onSubmit, children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" }) })] }));
};
export var MemoCommunicationView = memo(CommunicationView);
