import { request } from './request';
import { createEffect } from 'effector';
var get = createEffect(function () { return request('team/', { method: 'GET' }); });
export var getReports = createEffect(function (_a) {
    var custom_strategy_id = _a.custom_strategy_id, startDate = _a.startDate, endDate = _a.endDate;
    return request("reports/referrals?custom_strategy_id=".concat(custom_strategy_id, "&start_date=").concat(startDate, "&end_date=").concat(endDate), { method: 'GET' });
});
export var team = {
    get: get,
    getReports: getReports,
};
