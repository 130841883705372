var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { contactCustomStrategyStatus, customStrategyStatus } from '@/shared/api';
import { $$activeStrategy } from '@/shared/effector';
import { RequestFactory } from '@/shared/factories';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
var mapStatusToContactId = function (items) {
    return items.reduce(function (t, item) {
        var _a;
        return (__assign(__assign({}, t), (_a = {}, _a[item.contact_id.contact_id] = item.custom_strategy_status_id, _a)));
    }, {});
};
var updateOneItem = function (items, updatedItem) {
    var copied = __spreadArray([], items, true);
    var index = copied.findIndex(function (item) { return item.contact_id.contact_id === updatedItem.contact_id.contact_id; });
    if (index === -1) {
        return items;
    }
    copied[index] = updatedItem;
    return copied;
};
//* Consts
var LIMIT = 10;
//* Factories
var _a = RequestFactory({
    name: 'get-all CONTACT_CUSTOM_STRATEGY_STATUSES',
    api: contactCustomStrategyStatus.getAll,
}), requestSubmited = _a.submited, request = __rest(_a, ["submited"]);
var getOne = RequestFactory({
    name: 'get-one CONTACT_CUSTOM_STRATEGY_STATUSES',
    api: contactCustomStrategyStatus.getByContact,
});
//* Stores
var $limit = createStore(LIMIT);
var $offset = createStore(0);
export var updateContactStatus = createEvent();
var $items = createStore([]);
export var updateContactStatusFx = createEffect(function (obj) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        // console.log("obj.value", obj.custom_strategy_status_id, "obj.data", obj.data)
        return [2 /*return*/, customStrategyStatus.update(__assign(__assign({}, obj.data), { custom_strategy_status_id: obj.custom_strategy_status_id }))];
    });
}); });
$items.on(updateContactStatusFx.done, function (state, _a) {
    var params = _a.params, result = _a.result;
    // console.log("state", state)
    // console.log("params", params)
    // console.log("result", result)
    return state.map(function (item) {
        // console.log("item", item)
        if (item.contact_id.contact_id === (params === null || params === void 0 ? void 0 : params.сontactId)) {
            return __assign(__assign({}, item), { custom_strategy_status_id: result });
        }
        return item;
    });
});
// console.log("$items", $items)
var $mapStatusToContactId = $items.map(function (items) { return mapStatusToContactId(items); });
var $countContactsInStatuses = $mapStatusToContactId.map(function (items) {
    var result = {};
    Object.keys(items).forEach(function (key) {
        var status_id = items[+key].custom_strategy_status_id;
        result[status_id] = (result[status_id] || 0) + 1;
    });
    return result;
});
//* Event
var oneUpdated = createEvent();
var reloaded = createEvent();
//* Logic
// Get All
reset({
    clock: [$$activeStrategy.activeStrategyChanged, reloaded],
    target: [$items, $offset],
});
sample({
    clock: [$$activeStrategy.activeStrategyChanged, reloaded],
    source: {
        strategy_id: $$activeStrategy.$activeStrategyId,
        limit: $limit,
        offset: $offset,
    },
    target: requestSubmited,
});
sample({
    clock: request.done,
    source: $items,
    fn: function (currentItems, response) { return __spreadArray(__spreadArray([], currentItems, true), response.items, true); },
    target: $items,
});
sample({
    clock: request.done,
    source: $offset,
    fn: function (offset, response) { return offset + response.items.length; },
    target: $offset,
});
sample({
    clock: request.done,
    source: {
        strategy_id: $$activeStrategy.$activeStrategyId,
        limit: $limit,
        offset: $offset,
    },
    filter: function (_a, response) {
        var limit = _a.limit;
        return response.items.length === limit;
    },
    target: requestSubmited,
});
// Get One
sample({
    clock: oneUpdated,
    source: $$activeStrategy.$activeStrategyId,
    fn: function (strategy_id, contact_id) { return ({ strategy_id: strategy_id, contact_id: contact_id }); },
    target: getOne.submited,
});
sample({
    clock: getOne.done,
    source: $items,
    fn: updateOneItem,
    target: $items,
});
export var $$contactCustomStrategyStatuses = __assign(__assign({}, request), { $items: $items, $mapStatusToContactId: $mapStatusToContactId, $countContactsInStatuses: $countContactsInStatuses, oneUpdated: oneUpdated, reloaded: reloaded });
