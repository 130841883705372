import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Blob } from '../blob/ui';
import { Button } from '../buttons';
import './styles.scss';
import { ModalCloseIcon } from '@/shared/assets';
import { $$alerts, $$balance } from '@/shared/effector';
import { $isModalOpen, closeModal } from '@/shared/effector/finance/modal-status';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
var ModalFinance = function () {
    var isOpenModal = useUnit([$isModalOpen])[0];
    var showAlertDanger = useUnit($$alerts.showDanger);
    var showAlertSuccess = useUnit($$alerts.showSuccess);
    var balance = useUnit([$$balance.$balance])[0]; //сейчас с бэка приходит ноль
    // const balance = 1220
    // console.log("balance", balance)
    var _a = useUnit([$$balance.$value, $$balance.valueChanged]), value = _a[0], setValue = _a[1];
    // console.log("value", value)
    var submit = useUnit([$$balance.submited])[0];
    var handleChangeInput = function (e) {
        if (Number(e.target.value) || e.target.value === '') {
            setValue(Number(e.target.value));
        }
        else {
            showAlertDanger('Вводить только числа');
        }
    };
    var handleClickButton = function (valueInput) {
        if (valueInput >= 10 && valueInput <= balance) {
            submit();
            // showAlertSuccess('Заявка на вывод создана.')
            closeModal();
        }
        else if (valueInput < 10) {
            showAlertDanger('Минимальная сумма вывода 10$.');
        }
        else {
            showAlertDanger('Недостаточно средств на балансе.');
        }
    };
    useEffect(function () {
        if (isOpenModal)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'initial';
    }, [isOpenModal]);
    return (_jsx("div", { className: isOpenModal ? 'modall open' : 'modall close', onClick: function () { return closeModal(); }, children: _jsxs("div", { className: "modal-content", onClick: function (e) {
                e.stopPropagation();
            }, children: [_jsx("div", { onClick: function () { return closeModal(); }, className: "icon-close", children: _jsx(ModalCloseIcon, { width: 51, height: 51, className: "icon-modal" }) }), _jsx(Blob, { image: 'BlobWebpBlue' }), _jsx("p", { className: "title", children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0441\u0443\u043C\u043C\u0443 \u0432\u044B\u0432\u043E\u0434\u0430." }), _jsx("p", { className: "text", children: "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0441\u0443\u043C\u043C\u0430 10$." }), _jsx("p", { className: "text", children: "\u041A\u043E\u043C\u043C\u0438\u0441\u0438\u044F \u0437\u0430 \u0433\u0430\u0437 1% \u043E\u0442 \u0441\u0443\u043C\u043C\u044B \u043F\u0435\u0440\u0435\u0432\u043E\u0434\u0430." }), _jsx("input", { className: "modal-content-input", value: value, onChange: handleChangeInput }), _jsx(Button, { variant: "primary", onClick: function () { return handleClickButton(value); }, children: "\u0412\u044B\u0432\u0435\u0441\u0442\u0438" })] }) }));
};
export default ModalFinance;
