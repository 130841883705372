import { landing } from '@/shared/api';
import { attach, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
var getByLoginFx = attach({ effect: landing.getByLogin });
var $landing = createStore(null);
var $pending = getByLoginFx.pending;
var submited = createEvent();
var reseted = createEvent();
// Submited
sample({
    clock: submited,
    fn: function (login) { return ({ login: login }); },
    target: getByLoginFx,
});
// Done
sample({
    clock: getByLoginFx.doneData,
    target: $landing,
});
// Resetes
reset({
    clock: reseted,
    target: [$landing],
});
export var $$landingRefPage = {
    $landing: $landing,
    $pending: $pending,
    submited: submited,
    reseted: reseted,
};
