import { jsx as _jsx } from "react/jsx-runtime";
import { MemoButton } from '@/shared/components';
import { $$activeStrategy } from '@/shared/effector';
import { routes } from '@/shared/router';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo, useCallback } from 'react';
export var EditStrategyButton = function (_a) {
    var className = _a.className, strategy = _a.strategy;
    // Effector
    var activeStrategyChanged = useUnit($$activeStrategy.activeStrategyChanged);
    // Variables
    var ClassName = classNames('edit-strategy-button', className);
    // Handler
    var handleCLick = useCallback(function () {
        activeStrategyChanged(strategy.custom_strategy_id);
    }, [activeStrategyChanged, strategy.custom_strategy_id]);
    return (_jsx(MemoButton, { className: ClassName, to: routes.account.strategy.edit, onClick: handleCLick, children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" }));
};
export var MemoEditStrategyButton = memo(EditStrategyButton);
