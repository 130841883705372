import { useEffect, useState } from 'react';
export function useMediaQuery(query) {
    var _a = useState(false), matches = _a[0], setMatches = _a[1];
    useEffect(function () {
        var matchQueryList = window.matchMedia(query);
        setMatches(matchQueryList.matches);
        var handleChange = function (e) {
            setMatches(e.matches);
        };
        matchQueryList.addEventListener('change', handleChange);
        return function () {
            matchQueryList.removeEventListener('change', handleChange);
        };
    }, [query]);
    return matches;
}
