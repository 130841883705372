import { useMemo } from 'react';
export var useApexAreaOptions = function (type) {
    // Memo
    var config = useMemo(function () { return ({
        options: {
            chart: {
                type: 'area',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            grid: {
                show: false,
            },
            legend: {
                show: false,
            },
            stroke: {
                curve: 'smooth',
            },
            tooltip: {
                x: {
                    show: false,
                },
            },
            xaxis: {
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                type: type,
            },
            yaxis: {
                show: false,
            },
        },
    }); }, [type]);
    return config;
};
