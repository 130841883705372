import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { Button } from '@/shared/components/buttons';
import { $$alerts, $$session } from '@/shared/effector';
import { useCopy } from '@/shared/hooks';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo, useCallback } from 'react';
export var CopyInviteLinkButton = function (_a) {
    var className = _a.className;
    // Effector
    var login = useUnit($$session.$login);
    var showAlert = useUnit($$alerts.showSuccess);
    // Hooks
    var onCopy = useCopy(location.origin + '/?ref=' + login).onCopy;
    // Variables
    var ClassName = classNames('copy-invite-link-button', className);
    // Handlers
    var handleCopy = useCallback(function () {
        onCopy();
        showAlert('Скопировано');
    }, [onCopy, showAlert]);
    return (_jsx(Button, { className: ClassName, onClick: handleCopy, children: "\u0421\u0441\u044B\u043B\u043A\u0430 \u0434\u043B\u044F \u043F\u0440\u0438\u0433\u043B\u0430\u0448\u0435\u043D\u0438\u044F" }));
};
export var MemoCopyInviteLinkButton = memo(CopyInviteLinkButton);
