import { $$contactEditPage } from '@/shared/effector';
import { PageTitleFactory } from '@/shared/factories';
import { routes } from '@/shared/router';
import { sample } from 'effector';
export var currentRoute = routes.account.contact.edit;
PageTitleFactory({
    title: 'Редактирование контакта',
    route: currentRoute,
});
sample({
    clock: currentRoute.opened,
    fn: function (_a) {
        var params = _a.params;
        return params.contactId;
    },
    target: $$contactEditPage.submited,
});
sample({
    clock: currentRoute.closed,
    target: $$contactEditPage.reseted,
});
