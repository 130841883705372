import { purchase } from '@/shared/api/purchase';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getCurrentTotalFx = attach({ effect: purchase.getTotal });
var $value = createStore(0);
var $pending = getCurrentTotalFx.pending;
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    source: $pending,
    filter: function (pending) { return !pending; },
    target: getCurrentTotalFx,
});
// Done
sample({
    clock: getCurrentTotalFx.doneData,
    fn: function (_a) {
        var total_purchases = _a.total_purchases;
        return total_purchases;
    },
    target: $value,
});
export var $$currentTotal = {
    $value: $value,
    $pending: $pending,
    submited: submited,
};
