import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
export var Form = function (_a) {
    var className = _a.className, footerClassName = _a.footerClassName, children = _a.children, onSubmit = _a.onSubmit, footer = _a.footer;
    // Variables
    var ClassName = classNames('my-form', className);
    var FooterClassName = classNames('my-form__footer', footerClassName);
    // Handlers
    var handleSubmit = useCallback(function (event) {
        event.preventDefault();
        onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
    }, [onSubmit]);
    return (_jsxs("form", { className: ClassName, onSubmit: handleSubmit, children: [_jsx("div", { className: "my-form__content", children: children }), footer && _jsx("div", { className: FooterClassName, children: footer })] }));
};
export var MemoForm = memo(Form);
