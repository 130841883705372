import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { $isModalOpen, openModal, closeModal } from "@/shared/effector/promocode/modal-status";
import { useUnit } from "effector-react";
import React from "react";
import { ModalCloseIcon } from "@/shared/assets";
import "./styles.scss";
import { Blob } from '../blob/ui';
import { Button } from "../buttons";
import { $$alerts } from "@/shared/effector";
import { $$promocode } from "@/shared/effector/promocode/promocode";
export var ModalPromocode = function (_a) {
    // const profile = useUnit($$session.$profile)
    // console.log("profile", profile)
    var _b = useUnit([
        $$promocode.$value,
        $$promocode.valueChanged
    ]), value = _b[0], setValue = _b[1];
    var submited = useUnit([$$promocode.submited])[0];
    var _c = useUnit([$isModalOpen, openModal, closeModal]), isOpenModal = _c[0], open = _c[1], close = _c[2];
    var showAlertDanger = useUnit($$alerts.showDanger);
    var handleClickButton = function () {
        if (!value) {
            showAlertDanger("Введите промокод");
        }
        else {
            submited();
        }
    };
    var handleChangeInput = function (event) {
        setValue(event.target.value);
    };
    return (_jsx("div", { className: isOpenModal ? 'modall open' : 'modall close', onClick: close, children: _jsxs("div", { className: "modal-content", onClick: function (e) {
                e.stopPropagation();
            }, children: [_jsx("div", { onClick: close, className: "icon-close", children: _jsx(ModalCloseIcon, { width: 51, height: 51, className: "icon-modal" }) }), _jsx(Blob, { image: 'BlobWebpBlue' }), _jsx("p", { className: "title-promocode", children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }), _jsx("input", { className: "modal-content-input-promocode", value: value, onChange: function (event) { return handleChangeInput(event); } }), _jsx(Button, { className: "modal-content-button-promocode", variant: "primary", onClick: handleClickButton, children: "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C" })] }) }));
};
