import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { Form } from '@/shared/components';
import { Link } from 'atomic-router-react';
import classNames from 'classnames';
import { memo } from 'react';
export var AuthForm = function (_a) {
    var className = _a.className, title = _a.title, subtitle = _a.subtitle, children = _a.children, underForm = _a.underForm, buttonSlot = _a.buttonSlot, link = _a.link, onSubmit = _a.onSubmit;
    // Variables
    var ClassName = classNames('auth-form', className);
    var _b = link || {}, _c = _b.beforeLink, beforeLink = _c === void 0 ? '' : _c, text = _b.text, to = _b.to;
    return (_jsxs(Form, { className: ClassName, onSubmit: onSubmit, children: [_jsxs("div", { className: "auth-form__text", children: [_jsx("div", { className: "auth-form__title", children: title }), _jsx("div", { className: "auth-form__subtitle", children: subtitle })] }), children, underForm && _jsx("div", { className: "auth-form__under-form", children: underForm }), _jsx("div", { className: "auth-form__button", children: buttonSlot }), link && (_jsxs("div", { className: "auth-form__link", children: [beforeLink + ' ', _jsx(Link, { to: to, children: text })] }))] }));
};
export var MemoAuthForm = memo(AuthForm);
