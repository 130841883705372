var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { request } from '../request';
import { myMoment } from '@/shared/tools';
import { createEffect } from 'effector';
export var update = createEffect(function (_a) {
    var contact_id = _a.contact_id, birth_date = _a.birth_date, data = __rest(_a, ["contact_id", "birth_date"]);
    var validBirthDate = myMoment(birth_date).format('YYYY-MM-DDTHH:mm:ss.SSS');
    return request("contacts/".concat(contact_id), {
        method: 'PUT',
        data: __assign({ birth_date: validBirthDate }, data),
    });
});
