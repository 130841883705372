var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { Form } from '@/shared/components';
import { $$activeStrategy } from '@/shared/effector';
import { $$contactCustomStrategyStatuses, updateContactStatusFx } from '@/shared/effector/contact';
import classNames from 'classnames';
import { useStoreMap, useUnit } from 'effector-react';
import { memo, useCallback } from 'react';
export var StatusSelect = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 'sm' : _b, contact = _a.contact;
    // Effector
    var _c = useUnit([
        $$activeStrategy.$statuses,
        $$activeStrategy.$activeStrategyId,
    ]), statuses = _c[0], strategyId = _c[1];
    console.log('contact в селекте', contact);
    // const lastContact: ContactWithInfo = useUnit($$lastContact.$item)
    // const contactEditPage = useUnit($$contactEditPage.$contact)
    // const activeContact = useUnit($$activeContact.$item)
    // Variables
    var ClassName = classNames('status-select', className);
    var status = useStoreMap({
        store: $$contactCustomStrategyStatuses.$mapStatusToContactId,
        keys: [contact === null || contact === void 0 ? void 0 : contact.contact_id],
        fn: function (items) {
            return (contact === null || contact === void 0 ? void 0 : contact.contact_id) in items ? items[contact === null || contact === void 0 ? void 0 : contact.contact_id] : null;
        },
    });
    console.log('status status-select', status);
    //   console.log("status name?", status?.name)
    var statusesForSelect = statuses === null || statuses === void 0 ? void 0 : statuses.map(function (status) {
        // console.log("status", status)
        return {
            value: status.custom_strategy_status_id,
            text: status.name,
        };
    });
    var handleChange = useCallback(function (value) {
        if (strategyId) {
            // console.log("value", value)
            var currentStatus = statuses.find(function (status) { return (status === null || status === void 0 ? void 0 : status.custom_strategy_status_id) === value; });
            // console.log("currentStatus", currentStatus)
            var custom_strategy_id = currentStatus.custom_strategy_id, custom_strategy_status_id = currentStatus.custom_strategy_status_id, data = __rest(currentStatus
            // console.log("data", data)
            , ["custom_strategy_id", "custom_strategy_status_id"]);
            // console.log("data", data)
            updateContactStatusFx({
                data: data,
                custom_strategy_status_id: value,
                сontactId: contact.contact_id,
            });
        }
    }, [strategyId]);
    if (!strategyId && !statuses) {
        return null;
    }
    return (_jsx(Form.Select, { className: ClassName, size: size, items: statusesForSelect, value: status === null || status === void 0 ? void 0 : status.custom_strategy_status_id, onChange: handleChange }));
};
export var MemoStatusSelect = memo(StatusSelect);
