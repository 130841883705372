import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Blob } from '../blob/ui';
import { Button } from '../buttons';
import styles from './modal-communication.module.scss';
import './styles.scss';
import { useContactStatus } from '@/entities/contact';
import { ModalCloseIcon } from '@/shared/assets';
import { $$activeStrategy } from '@/shared/effector';
import { $$lastContact } from '@/shared/effector/contact';
import { $isModalContactOpen, closeModalContact } from '@/shared/effector/contact/modal-contact';
import { useHideHtmlTags } from '@/shared/hooks/use-hide-htmlTags';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
var ModalContact = function () {
    var _a = useState(false), copy = _a[0], setCopy = _a[1];
    var isOpenModal = useUnit([$isModalContactOpen])[0];
    var lastContact = useUnit($$lastContact.$item);
    var status = useContactStatus(lastContact === null || lastContact === void 0 ? void 0 : lastContact.contact_id).status;
    var steps = useUnit([$$activeStrategy.$steps])[0];
    var step = steps.find(function (step) { return (step === null || step === void 0 ? void 0 : step.custom_strategy_step_id) === (status === null || status === void 0 ? void 0 : status.custom_strategy_next_step_id); });
    var handleCopy = function () {
        navigator.clipboard.writeText((step === null || step === void 0 ? void 0 : step.comment) ? useHideHtmlTags(step === null || step === void 0 ? void 0 : step.comment) : 'Описание шага отсутствует');
        setCopy(true);
    };
    useEffect(function () {
        if (isOpenModal)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'initial';
    }, [isOpenModal]);
    useEffect(function () {
        if (copy) {
            setTimeout(function () {
                setCopy(false);
            }, 2000);
        }
    }, [copy, lastContact]);
    var handleTelegramWhatsapp = function (type) {
        var url = type === 'Telegram'
            ? "https://t.me/".concat((lastContact === null || lastContact === void 0 ? void 0 : lastContact.telegram_ID) ? lastContact === null || lastContact === void 0 ? void 0 : lastContact.telegram_ID : lastContact === null || lastContact === void 0 ? void 0 : lastContact.phone, "?text=").concat((step === null || step === void 0 ? void 0 : step.comment) ? useHideHtmlTags(step === null || step === void 0 ? void 0 : step.comment) : 'Описание шага отсутствует')
            : type === 'WhatsApp'
                ? "https://wa.me/".concat(lastContact === null || lastContact === void 0 ? void 0 : lastContact.phone, "?text=").concat((step === null || step === void 0 ? void 0 : step.comment) ? useHideHtmlTags(step === null || step === void 0 ? void 0 : step.comment) : 'Описание шага отсутствует')
                : null;
        if (type === 'Telegram' || type === 'WhatsApp')
            window.open(url, '_blank');
    };
    return (_jsx("div", { className: isOpenModal ? 'modall open' : 'modall close', onClick: function () { return closeModalContact(); }, children: _jsxs("div", { className: "modal-content", onClick: function (e) {
                e.stopPropagation();
            }, children: [_jsx("div", { onClick: function () { return closeModalContact(); }, className: "icon-close", children: _jsx(ModalCloseIcon, { width: 51, height: 51, className: "icon-modal" }) }), _jsx(Blob, { image: 'BlobWebpBlue' }), _jsxs("div", { className: styles.script_container, children: [_jsx("div", { className: "text", children: "\u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435" }), _jsx("p", { className: "text", children: useHideHtmlTags(step === null || step === void 0 ? void 0 : step.comment) || 'Описание шага отсутствует' })] }), _jsx("p", { className: "title", children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u043F\u043E\u0441\u043E\u0431 \u0441\u0432\u044F\u0437\u0438" }), _jsxs("div", { className: "button-contacts-wrapper", children: [_jsx(Button, { variant: "primary", circle: true, onClick: function () { return handleTelegramWhatsapp('Telegram'); }, className: "long-button", children: "Telegram" }), _jsx(Button, { variant: "primary", circle: true, onClick: function () { return handleTelegramWhatsapp('WhatsApp'); }, className: "long-button", children: "WhatsApp" }), _jsx(Button, { variant: "primary", circle: true, className: "long-button", onClick: function () { return handleCopy(); }, children: copy ? 'Скопировано' : 'Копировать' })] })] }) }));
};
export default ModalContact;
