import { jsx as _jsx } from "react/jsx-runtime";
import './index.scss';
import { App } from '@/app';
import { $$app } from '@/shared/effector';
import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
console.log('hello?');
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(App, {}));
$$app.started();
