import { $$landingRefPage, $$session } from '@/shared/effector';
import { routes } from '@/shared/router';
import { sample } from 'effector';
export var currentRoute = routes.landingRef;
sample({
    clock: currentRoute.opened,
    fn: function (_a) {
        var params = _a.params;
        return params.login;
    },
    target: [$$landingRefPage.submited, $$session.refLoginChanged],
});
sample({
    clock: currentRoute.closed,
    target: $$landingRefPage.reseted,
});
