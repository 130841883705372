import { $$session } from '../session';
import { $$activeContact } from '@/entities/contact/model';
import { communication } from '@/shared/api';
import { $$activeStrategy } from '@/shared/effector';
import { CreateFactory, GetAllFactory } from '@/shared/factories';
import { createEvent, sample } from 'effector';
import { combineEvents } from 'patronum';
//* Get All
var getAll = GetAllFactory({
    name: 'COMMUNICATION',
    api: communication.getAll,
});
//* Create
var create = CreateFactory({
    name: 'COMMUNICATION',
    api: communication.create,
    useDebug: true,
});
//* Events
var submitWithContact = createEvent();
var submitWithStrategy = createEvent();
var allLoaded = combineEvents({
    events: {
        contact: $$activeContact.done,
        strategy: $$activeStrategy.requestes.strategy.done,
    },
});
sample({
    clock: $$session.authed,
    target: getAll.submited,
});
sample({
    clock: allLoaded,
    fn: function (_a) {
        var contact = _a.contact, strategy = _a.strategy;
        return ({
            contact_id: contact.contact_id,
            strategy_id: strategy.custom_strategy_id,
        });
    },
    target: getAll.submited,
});
sample({
    clock: $$activeContact.done,
    target: submitWithContact,
});
sample({
    clock: submitWithContact,
    source: $$activeStrategy.$strategy,
    filter: function (item) { return Boolean(item); },
    fn: function (strategy, contact) { return ({
        contact_id: contact.contact_id,
        strategy_id: strategy.custom_strategy_id,
    }); },
    target: getAll.submited,
});
sample({
    clock: $$activeStrategy.requestes.strategy.done,
    target: submitWithStrategy,
});
sample({
    clock: submitWithStrategy,
    source: $$activeContact.$item,
    filter: function (item) { return Boolean(item); },
    fn: function (contact, strategy) { return ({
        contact_id: contact.contact_id,
        strategy_id: strategy.custom_strategy_id,
    }); },
    target: getAll.submited,
});
export var $$communication = {
    getAll: getAll,
    create: create,
    submitWithContact: submitWithContact,
};
