import { Cloud, CloudCustom, Footer, Header, MemoLoader, MemoSmallWidget, MemoSmallWidgetLevels, Sidebar } from './components';
import { PageLayout as Layout } from './ui';
export var PageLayout = Object.assign(Layout, {
    Sidebar: Sidebar,
    Header: Header,
    Footer: Footer,
    Cloud: Cloud,
    CloudCustom: CloudCustom,
    Loader: MemoLoader,
    SmallWidget: MemoSmallWidget,
    SmallWidgetLevels: MemoSmallWidgetLevels
});
