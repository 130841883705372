import { chainRoute, redirect } from 'atomic-router';
import { routes } from "@/shared/router";
import { createEvent, createStore, sample } from "effector";
var $isAdmin = createStore(false);
var setIsAdmin = createEvent();
$isAdmin.on(setIsAdmin, function (_state, value) { return value; });
function chainAdmin(route) {
    var checkAdminAccess = createEvent();
    var userIsAdmin = sample({
        clock: checkAdminAccess,
        filter: $isAdmin,
    });
    var userIsNotAdmin = sample({
        clock: checkAdminAccess,
        filter: $isAdmin.map(function ($isAdmin) { return !$isAdmin; })
    });
    redirect({
        clock: userIsNotAdmin,
        route: routes.account.main
    });
    return chainRoute({
        route: route,
        beforeOpen: checkAdminAccess,
        openOn: [userIsAdmin],
        cancelOn: [userIsNotAdmin]
    });
}
export { $isAdmin, setIsAdmin, chainAdmin };
