import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { ThemeHeaderIcon } from '@/shared/assets';
import AuthLogo from '@/shared/assets/images/auth-logo.png';
import { useTheme } from '@/shared/theme';
import classNames from 'classnames';
import { memo } from 'react';
export var Management = function (_a) {
    var className = _a.className;
    // Hooks
    var toggle = useTheme().toggle;
    // Variables
    var ClassName = classNames('auth-partner-management', className);
    return (_jsxs("div", { className: ClassName, children: [_jsxs("div", { className: "auth-partner-management__content", children: [_jsx("img", { className: "auth-partner-management__logo", src: AuthLogo }), _jsx("div", { className: "auth-partner-management__button", children: "Partner Relationship Management" })] }), _jsxs("div", { className: "auth-partner-management__footer", children: [_jsx("div", { className: "auth-partner-management__footer-item", children: "Web 3.0" }), _jsx("div", { className: "auth-partner-management__footer-item", children: "WhitePaper" }), _jsx("div", { className: "auth-partner-management__footer-item", children: "Site" }), _jsx("div", { className: "auth-partner-management__footer-item", children: "Presentation" })] }), _jsx("div", { className: "auth-partner-management__theme-toggle", onClick: toggle, children: _jsx(ThemeHeaderIcon, { width: 24, height: 24 }) })] }));
};
export var MemoManagement = memo(Management);
