import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import NoAvatarDark from '@/assets/account/images/no-avatar-dark.png';
import NoAvatarLight from '@/assets/account/images/no-avatar-light.png';
import { useTheme } from '@/shared/theme';
import classNames from 'classnames';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { $$contactAvatar } from '@/shared/effector/contact/contact-avatar';
import { useUnit } from 'effector-react';
export var Avatar = function (_a) {
    var className = _a.className, otherSrc = _a.src, size = _a.size, isSkeleton = _a.isSkeleton;
    // Hooks
    var theme = useTheme().theme;
    // Variables
    var ClassName = classNames('avatar', className);
    var src = (otherSrc !== null && otherSrc !== void 0 ? otherSrc : theme === 'light') ? NoAvatarLight : NoAvatarDark;
    // const src = otherSrc ? otherSrc : theme === 'light' ? NoAvatarLight : NoAvatarDark
    // console.log("otherSrc", otherSrc)
    // console.log("src", src)
    var _b = useUnit([
        $$contactAvatar.$avatar, $$contactAvatar.$contactId
    ]), avatar = _b[0], contact_id = _b[1];
    // console.log("avatar", avatar)
    // console.log("contact_id", contact_id)
    // const contact = useUnit($$activeContact.$item)
    // const getSrc = () => {
    //   let src
    //   if (avatar) {
    //     if (avatar?.get('avatar') instanceof Blob) {
    //       src = URL.createObjectURL(avatar?.get('avatar') as Blob)
    //     }
    //   } else {
    //     src = theme === 'light' ? NoAvatarLight : NoAvatarDark
    //   }
    //   return src
    // }
    // console.log("getSrc", getSrc())
    // Memo
    var style = useMemo(function () {
        return size
            ? {
                minWidth: "".concat(size, "px"),
                maxWidth: "".concat(size, "px"),
                minHeight: "".concat(size, "px"),
                maxHeight: "".concat(size, "px"),
                borderRadius: "".concat(size, "px"),
            }
            : undefined;
    }, [size]);
    // Render
    if (isSkeleton) {
        return (_jsx(Skeleton, { style: style, className: ClassName, width: size, height: size, circle: true }));
    }
    return (_jsx("img", { style: style, className: ClassName, src: src, 
        // src={getSrc()}
        // src={contact?.avatar}
        alt: "avatar" }));
};
