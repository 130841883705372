export function useSpace(value) {
    var res;
    var str;
    if (typeof value === 'number') {
        str = String(value);
    }
    else {
        str = value;
    }
    str.indexOf(',') != -1
        ? (res = new Intl.NumberFormat('ru-RU')
            .format(parseFloat(str.replace(',', '.')))
            .replace('.', ','))
        : (res = new Intl.NumberFormat('ru-RU').format(parseFloat(str)).replace(',', '.'));
    return res;
}
