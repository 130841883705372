import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUnit } from "effector-react";
import React from "react";
import { ModalCloseIcon } from "@/shared/assets";
import "./styles.scss";
import { Blob } from '../blob/ui';
import { $isModalOpen, openModal, closeModal } from "@/shared/effector/lottery-tickets/modal-status";
import { $$lotteryTickets } from "@/shared/effector/lottery-tickets/lottery-tickets";
export var ModalLotteryTickets = function (_a) {
    var _b = useUnit([$isModalOpen, openModal, closeModal]), isOpenModal = _b[0], open = _b[1], close = _b[2];
    var tickets = useUnit([$$lotteryTickets.$tickets])[0]; // сейчас возвращает пустой массив
    // console.log("tickets", tickets)
    // const tickets = ['билет 1', 'билет 2', 'билет 3', 'билет 4', 'билет 5', 'билет 6', 'билет 7', 'билет 8', 'билет 9']
    return (_jsx("div", { className: isOpenModal ? 'modall open' : 'modall close', onClick: close, children: _jsxs("div", { className: "modal-content", onClick: function (e) {
                e.stopPropagation();
            }, children: [_jsx("div", { onClick: close, className: "icon-close", children: _jsx(ModalCloseIcon, { width: 51, height: 51, className: "icon-modal" }) }), _jsx(Blob, { image: 'BlobWebpBlue' }), _jsxs("p", { className: 'title-promocode', children: ["\u0423 \u0432\u0430\u0441 ", tickets.length, " \u0443\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0445 \u043D\u043E\u043C\u0435\u0440\u043E\u0432"] }), _jsx("p", { className: "text-promocode", children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u0441\u043C\u043E\u0442\u0440\u0438\u0442\u0435 \u0432 \u043D\u0430\u0448\u0435\u043C telegram \u043A\u0430\u043D\u0430\u043B\u0435" }), _jsx("div", { className: 'scrolling-area', children: tickets && tickets.length > 0 && tickets.map(function (ticket) { return _jsx("p", { className: 'ticket-item', children: ticket }, ticket); }) })] }) }));
};
