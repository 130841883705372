import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import './style.scss';
import { useFunnelStatuses } from '@/shared/hooks/strategy/use-funnel-statuses';
var FunnelChart = function () {
    var items = useFunnelStatuses({}).items;
    var svgRef = useRef();
    useEffect(function () {
        var svg = d3.select(svgRef.current)
            .attr('width', 300)
            .attr('height', 350);
        var margin = { top: 0, right: 0, bottom: 0, left: 0 };
        var width = 300;
        var height = 350;
        // const totalValue = data.reduce((acc, d) => acc + d.value, 0);
        svg.selectAll("*").remove();
        var chart = svg.append('g')
            .attr('transform', "translate(".concat(margin.left, ", ").concat(margin.top, ")"));
        // const colorScale = d3.scaleLinear()
        //     .domain([0, data.length])
        //     .range(["#11658a", "#57c3f2"]);
        var trapezoidWidth = width;
        var accumulatedHeight = 0;
        var widthReductionStep = 50;
        var currentBottomWidth = trapezoidWidth;
        items.forEach(function (d, i) {
            var trapezoidHeight = height / items.length;
            var topWidth = currentBottomWidth;
            var bottomWidth = topWidth - widthReductionStep;
            currentBottomWidth = bottomWidth;
            var x = (width - topWidth) / 2;
            var y = accumulatedHeight;
            var pathData = d3.path();
            pathData.moveTo(x, y);
            pathData.lineTo(x + topWidth, y);
            pathData.lineTo(x + topWidth - (topWidth - bottomWidth) / 2, y + trapezoidHeight);
            pathData.lineTo(x + (topWidth - bottomWidth) / 2, y + trapezoidHeight);
            pathData.closePath();
            chart.append('path')
                .attr('d', pathData.toString())
                .attr('fill', d.color)
                .attr('stroke', '#e4edf0');
            chart.append('text')
                .attr('x', x + topWidth / 2)
                .attr('y', y + trapezoidHeight / 2)
                .attr('text-anchor', 'middle')
                .attr('fill', 'white')
                .style('font-size', '12px')
                .text("".concat(d.name, " : ").concat(d.value));
            accumulatedHeight += trapezoidHeight;
        });
    }, [items]);
    return (_jsx("div", { className: 'funnel', children: _jsx("svg", { ref: svgRef, width: 300, height: 382 }) }));
};
export default FunnelChart;
