import { profile } from '@/shared/api';
import { $$alerts, $$session } from '@/shared/effector';
import { myMoment } from '@/shared/tools';
import { attach, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
var updateFx = attach({ effect: profile.update });
var $firstName = createStore('');
var $lastName = createStore('');
var $phone = createStore('');
var $birthday = createStore('');
var $city = createStore('');
var $email = createStore('');
var $password = createStore('');
var $pending = updateFx.pending;
var $error = createStore(null);
var firstNameChanged = createEvent();
var lastNameChanged = createEvent();
var phoneChanged = createEvent();
var birthdayChanged = createEvent();
var cityChanged = createEvent();
var emailChanged = createEvent();
var passwordChanged = createEvent();
var reseted = createEvent();
var submited = createEvent();
$firstName.on(firstNameChanged, function (_, value) { return value; });
$lastName.on(lastNameChanged, function (_, value) { return value; });
$phone.on(phoneChanged, function (_, value) { return value; });
$birthday.on(birthdayChanged, function (_, value) { return value; });
$city.on(cityChanged, function (_, value) { return value; });
$email.on(emailChanged, function (_, value) { return value; });
$password.on(passwordChanged, function (_, value) { return value; });
// Submited
sample({
    clock: submited,
    source: {
        first_name: $firstName,
        last_name: $lastName,
        phone: $phone,
        birthday: $birthday,
        city: $city,
        email: $email,
        password: $password,
    },
    target: updateFx,
});
// Done
sample({
    clock: updateFx.done,
    fn: function () { return 'Успешно сохранено!'; },
    target: $$alerts.showSuccess,
});
// Fail
sample({
    clock: updateFx.failData,
    target: $error,
});
// Reset
sample({
    clock: $$session.$profile.updates,
    target: reseted,
});
sample({
    clock: reseted,
    source: $$session.$profile,
    fn: function (profile) { return (profile === null || profile === void 0 ? void 0 : profile.first_name) || ''; },
    target: firstNameChanged,
});
sample({
    clock: reseted,
    source: $$session.$profile,
    fn: function (profile) { return (profile === null || profile === void 0 ? void 0 : profile.last_name) || ''; },
    target: lastNameChanged,
});
sample({
    clock: reseted,
    source: $$session.$profile,
    fn: function (profile) { return (profile === null || profile === void 0 ? void 0 : profile.phone) || ''; },
    target: phoneChanged,
});
sample({
    clock: reseted,
    source: $$session.$profile,
    fn: function (profile) { return myMoment((profile === null || profile === void 0 ? void 0 : profile.birthday) || new Date()).format('YYYY-MM-DD'); },
    target: birthdayChanged,
});
sample({
    clock: reseted,
    source: $$session.$profile,
    fn: function (profile) { return (profile === null || profile === void 0 ? void 0 : profile.city) || ''; },
    target: cityChanged,
});
sample({
    clock: reseted,
    source: $$session.$profile,
    fn: function (profile) { return (profile === null || profile === void 0 ? void 0 : profile.email) || ''; },
    target: emailChanged,
});
reset({
    clock: reseted,
    target: [$password],
});
reset({
    clock: [
        firstNameChanged,
        lastNameChanged,
        phoneChanged,
        birthdayChanged,
        cityChanged,
        emailChanged,
        passwordChanged,
    ],
    target: $error,
});
export var $$editProfileForm = {
    $firstName: $firstName,
    $lastName: $lastName,
    $phone: $phone,
    $birthday: $birthday,
    $city: $city,
    $email: $email,
    $password: $password,
    $pending: $pending,
    $error: $error,
    firstNameChanged: firstNameChanged,
    lastNameChanged: lastNameChanged,
    phoneChanged: phoneChanged,
    birthdayChanged: birthdayChanged,
    cityChanged: cityChanged,
    emailChanged: emailChanged,
    passwordChanged: passwordChanged,
    reseted: reseted,
    submited: submited,
    done: updateFx.done,
    fail: updateFx.fail,
};
