import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Footer, Header, Sidebar } from './components';
import './style.scss';
import { $$session, Auth } from '@/shared/effector';
import { useAuthorized } from '@/shared/hooks';
import { useTheme } from '@/shared/theme';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { Spinner } from 'react-bootstrap';
export var PageLayout = function (_a) {
    var className = _a.className, sidebar = _a.sidebar, header = _a.header, children = _a.children, footer = _a.footer, noCheckAuth = _a.noCheckAuth;
    // Effector
    var auth = useUnit($$session.$auth);
    // Hooks
    var theme = useTheme().theme;
    useAuthorized(!noCheckAuth);
    // Variables
    var ClassName = classNames('page-layout', className);
    // Render
    if (auth === Auth.Unknown) {
        return (_jsx("div", { className: "page-layout-loading", children: _jsx(Spinner, { variant: theme === 'light' ? 'dark' : 'light' }) }));
    }
    return (_jsxs("div", { className: ClassName, children: [_jsx("div", { className: "page-layout__sidebar", children: sidebar ? sidebar : _jsx(Sidebar, {}) }), _jsx("div", { className: "page-layout__header", children: header ? header : _jsx(Header, {}) }), children && _jsx("div", { className: "page-layout__content", children: children }), _jsx("div", { className: "page-layout__footer", children: footer ? sidebar : _jsx(Footer, {}) })] }));
};
