import { $$activeContact } from '@/entities/contact/model';
import { PageTitleFactory } from '@/shared/factories';
import { routes } from '@/shared/router';
import { $$contactCommunication } from '@/widgets/contact/contact-communication/model';
import { $$activeContactCommunications } from '@/widgets/contact/contact-work-history-table/model';
import { redirect } from 'atomic-router';
import { createEvent, sample } from 'effector';
import { condition } from 'patronum';
export var currentRoute = routes.account.contact.work;
PageTitleFactory({
    title: 'Работа с контактом',
    route: currentRoute,
});
//* Stores
var $isValidRoute = currentRoute.$params.map(function (params) { return Boolean(params.id); });
//* Events
var redirected = createEvent();
//* Logic
// Redirected
redirect({
    clock: redirected,
    route: routes.account.main,
});
// Opened
sample({
    clock: [currentRoute.opened],
    source: { pageParams: currentRoute.$params, contactParams: $$activeContact.$params },
    filter: function (_a) {
        var pageParams = _a.pageParams, contactParams = _a.contactParams;
        return Boolean(pageParams === null || pageParams === void 0 ? void 0 : pageParams.id) && Boolean(contactParams === null || contactParams === void 0 ? void 0 : contactParams.id) && pageParams.id !== contactParams.id;
    },
    target: $$activeContact.reseted,
});
sample({
    clock: [currentRoute.opened],
    source: $$activeContactCommunications.$params,
    filter: function (contactParams, pageParams) {
        return Boolean(pageParams.params.id) &&
            Boolean(contactParams === null || contactParams === void 0 ? void 0 : contactParams.contact_id) &&
            pageParams.params.id !== contactParams.contact_id;
    },
    target: $$activeContactCommunications.reseted,
});
condition({
    source: currentRoute.$params,
    if: $isValidRoute,
    then: $$activeContact.submited,
    else: redirected,
});
// Load Fail
sample({
    clock: $$activeContact.fail,
    target: redirected,
});
// Create Communication Done
sample({
    clock: $$contactCommunication.done,
    target: $$activeContact.reloaded,
});
// Closed
sample({
    clock: currentRoute.closed,
    target: $$activeContact.reseted,
});
//* Загрузить контакта
//* Есть контакт?
//* - Нет
//* Редирект
//* - Да
//* Отправка в модели
//* Коммуникация создана?
//* - Перезагрузить контакт
