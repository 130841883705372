import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { MemoSkeletonButton, PageLayout } from '@/shared/components';
import Skeleton from 'react-loading-skeleton';
export var SkeletonFinances = function () {
    return (_jsxs(PageLayout.Cloud, { contentClassName: "finances", header: {
            title: 'Финансы',
            subtitle: 'Покажи на что ты способен!',
        }, children: [_jsxs("div", { className: "finances__text", children: [_jsx(Skeleton, { className: "finances__value", width: 100 }), _jsx(Skeleton, { className: "finances__title", width: 65 })] }), _jsx("div", { className: "finances__buttons", children: _jsx(MemoSkeletonButton, {}) })] }));
};
