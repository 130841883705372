import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
export var Section = function (_a) {
    var anchor = _a.anchor, className = _a.className, children = _a.children, noMargin = _a.noMargin, usePaddings = _a.usePaddings, useDefaultMT = _a.useDefaultMT;
    // Variables
    var ClassName = classNames('landing-section', {
        'landing-section--no-margin': noMargin,
        'landing-section--use-paddings': usePaddings,
        'landing-section--use-default-mt': useDefaultMT,
    }, className);
    return (_jsxs(_Fragment, { children: [anchor && (_jsx("div", { id: anchor, className: "landing-section__anchor" })), _jsx("div", { className: ClassName, children: children })] }));
};
