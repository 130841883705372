import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { routes } from '@/shared/router';
import { Link } from 'atomic-router-react';
import classNames from 'classnames';
import { memo } from 'react';
export var BlickButton = function (_a) {
    var className = _a.className, children = _a.children;
    // Variables
    var ClassName = classNames('blick-button-wrapper', className);
    return (_jsx(Link, { className: ClassName, to: routes.auth.registration, children: _jsx("div", { className: "blick-button", children: children }) }));
};
export var MemoBlickButton = memo(BlickButton);
