import { pools } from '@/shared/api/pools';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getPoolsFx = attach({ effect: pools.getPools });
var $items = createStore([]);
$items.on(getPoolsFx.doneData, function (_, payload) { return payload; });
var $pending = getPoolsFx.pending;
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    target: getPoolsFx,
});
export var $$pools = {
    $items: $items,
    $pending: $pending,
    submited: submited,
};
