import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
export var SkeletonButton = function (_a) {
    var className = _a.className, _b = _a.width, width = _b === void 0 ? 120 : _b;
    // Variables
    var ClassName = classNames('button', className);
    return (_jsx(Skeleton, { className: ClassName, width: width, height: 38, borderRadius: 38 }));
};
export var MemoSkeletonButton = memo(SkeletonButton);
