import { currentRoute } from './model';
import { suspenseWrapper } from '@/pages/suspense-wrapper';
import { AuthLayout } from '@/shared/components';
import { lazy } from 'react';
export var Page = suspenseWrapper(lazy(function () { return import('./ui'); }));
export var ResetPasswordRoute = {
    view: Page,
    route: currentRoute,
    layout: AuthLayout,
};
