import { referral } from '@/shared/api/referral';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getActiveCountFx = attach({ effect: referral.getActiveCount });
var $value = createStore(0);
var $pending = getActiveCountFx.pending;
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    source: $pending,
    filter: function (pending) { return !pending; },
    target: getActiveCountFx,
});
// Done
sample({
    clock: getActiveCountFx.doneData,
    fn: function (_a) {
        var count = _a.count;
        return count;
    },
    target: $value,
});
export var $$activeCount = {
    $value: $value,
    $pending: $pending,
    submited: submited,
};
