import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Вторая модалка - Обратная связь
import { $$contactCommunication } from '../../model';
import './style.scss';
import { Form, MemoButton } from '@/shared/components';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo, useCallback } from 'react';
import { Form as BootstrapForm, Modal } from 'react-bootstrap';
export var ResponseView = function (_a) {
    var className = _a.className, onSubmit = _a.onSubmit;
    // Effector
    var results = useUnit([$$contactCommunication.info.$nextStepResults])[0];
    var _b = useUnit([
        $$contactCommunication.form.$stepResultId,
        $$contactCommunication.form.stepResultIdChanged,
    ]), resultId = _b[0], resultIdChanged = _b[1];
    var _c = useUnit([
        $$contactCommunication.form.$comment,
        $$contactCommunication.form.commentChanged,
    ]), comment = _c[0], commentChanged = _c[1];
    var canSubmit = useUnit([$$contactCommunication.form.$canSubmit])[0];
    // Variables
    var ClassName = classNames('response-view', className);
    // Handlers
    var handleChangeResult = useCallback(function (event) {
        resultIdChanged(parseInt(event.target.value));
    }, [resultIdChanged]);
    return (_jsxs("div", { className: ClassName, children: [_jsx(Modal.Header, { closeButton: true, children: _jsx(Modal.Title, { children: "\u041E\u0431\u0440\u0430\u0442\u043D\u0430\u044F \u0441\u0432\u044F\u0437\u044C" }) }), _jsxs(Modal.Body, { children: [_jsxs("div", { className: "response-view__section", children: [_jsx("div", { className: "response-view__section-header", children: "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442 \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0430" }), results.map(function (result) { return (_jsxs(BootstrapForm.Check, { type: "radio", id: "choose-result-type-".concat(result.custom_strategy_step_result_id), children: [_jsx(BootstrapForm.Check.Input, { type: "radio", value: result.custom_strategy_step_result_id, checked: result.custom_strategy_step_result_id === resultId, onChange: handleChangeResult }), _jsx(BootstrapForm.Check.Label, { children: result.result_name }), _jsx(BootstrapForm.Check.Label, { className: "response-view__result-description", children: result.result_description })] }, result.custom_strategy_step_result_id)); })] }), _jsxs("div", { className: "response-view__section", children: [_jsx("div", { className: "response-view__section-header", children: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439" }), _jsx(Form.Input, { type: "textarea", value: comment, onChange: commentChanged })] })] }), _jsx(Modal.Footer, { children: _jsx(MemoButton, { disabled: !canSubmit, onClick: onSubmit, children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" }) })] }));
};
export var MemoResponseView = memo(ResponseView);
