import { createEffect } from 'effector';
var get = createEffect(function () {
    return [
        {
            id: 1,
            name: 'PRM',
        },
        {
            id: 2,
            name: 'PRM 2',
        },
    ];
});
export var strategy = {
    get: get,
};
export * from './basic-strategy';
export * from './custom-strategy';
export * from './global-strategy-status-color';
export * from './contact-custom-strategy-status';
export * from './communication-type';
export * from './communication';
