import { NoTableData } from '../components';
import { useMediaQuery } from './use-media-query';
import { useTheme } from '@table-library/react-table-library/theme';
export var useTableTheme = function (_a) {
    var columnsSizes = _a.columns, isNoData = _a.isNoData;
    // Hooks
    var isLess1200 = useMediaQuery('screen and (max-width: 1200px)');
    var isLess768 = useMediaQuery('screen and (max-width: 768px)');
    var isLess425 = useMediaQuery('screen and (max-width: 425px)');
    // Variables
    var columns = isNoData
        ? NoTableData.size
        : isLess425 && columnsSizes.mobile
            ? columnsSizes.mobile
            : isLess768 && columnsSizes.tablet
                ? columnsSizes.tablet
                : isLess1200 && columnsSizes.laptop
                    ? columnsSizes.laptop
                    : columnsSizes.base;
    // console.log("columns", columns)
    // console.log("isNoData", isNoData)
    var editCellAbsolute = "\n    right: 0px;    \n    border-left: 1px solid var(--line-one);\n  ";
    return useTheme({
        Table: "\n      --data-table-library_grid-template-columns:  ".concat(columns, ";\n\n      &::-webkit-scrollbar {\n        height: 12px;\n        border-radius: 10px;\n      }\n      &::-webkit-scrollbar-thumb {\n        background: var(--scrollbar-color-line);\n        border-radius: 10px;\n      }\n      &::-webkit-scrollbar-track {\n        background: var(--background-color-four);\n      }\n    "),
        HeaderRow: "\n      background-color: var(--background-color-four);\n    ",
        HeaderCell: "\n      padding: 12px 12px;\n      border-bottom: 1px solid var(--line-one);\n\n      color: var(--nav-list-disable);\n      font-family: DM Sans, sans-serif;\n      font-size: 14px;\n      font-weight: 400;\n\n      &.edit-header-cell {\n        ".concat(!isLess768 ? editCellAbsolute : '', "\n      }\n    "),
        Row: "\n      background-color: var(--background-color-four);\n\n      &.clickable-row:hover {\n        background-color: var(--background-color-three);\n\n        cursor: pointer;\n      }\n    ",
        Cell: "\n      padding: 12px 12px;\n\n      color: var(--blue-first);\n      font-family: DM Sans, sans-serif;\n      font-size: 14px;\n      font-weight: 700;\n      \n      &.edit-cell {\n        ".concat(!isLess768 ? editCellAbsolute : '', "\n\n        cursor: pointer;\n      }\n\n      &.edit-cell:hover {\n        color: var(--text-color-three);\n      }\n    "),
    });
};
