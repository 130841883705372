import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
export var useCopy = function (text) {
    var onCopy = useCallback(function () {
        copy(text, {
            debug: true,
            message: 'Press #{key} to copy',
        });
    }, [text]);
    return { onCopy: onCopy };
};
