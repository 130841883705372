import { $$session } from '../session';
import { $$customStrategies } from './strategies';
import { customStrategy, customStrategyStatus, customStrategyStep, customStrategyStepResult, } from '@/shared/api';
import { CacheFactory, GetAllFactory, GetOneFactory } from '@/shared/factories';
import { createEvent, createStore, sample } from 'effector';
//* Cache
var strategyCache = CacheFactory({
    name: 'CUSTOM_STRATEGY',
    reset: $$session.logouted,
});
var stepsCache = CacheFactory({
    name: 'CUSTOM_STRATEGY_STEPS',
    reset: $$session.logouted,
});
var stepResultsCache = CacheFactory({
    name: 'CUSTOM_STRATEGY_STEP_RESULTS',
    reset: $$session.logouted,
});
var statusesCache = CacheFactory({
    name: 'CUSTOM_STRATEGY_STATUSES',
    reset: $$session.logouted,
});
//* Factories
var getStrategy = GetOneFactory({
    name: 'CUSTOM_STRATEGY',
    api: customStrategy.getOne,
});
var getSteps = GetAllFactory({
    name: 'CUSTOM_STRATEGY_STEPS',
    api: customStrategyStep.getAll,
});
var getStepResults = GetAllFactory({
    name: 'CUSTOM_STRATEGY_STEP_RESULTS',
    api: customStrategyStepResult.getAll,
});
var getStatuses = GetAllFactory({
    name: 'CUSTOM_STRATEGY_STATUSES',
    api: customStrategyStatus.getAll,
});
//* Stores
var $activeStrategyId = createStore(null);
var $strategy = createStore(null);
var $steps = createStore([]);
var $stepResults = createStore([]);
var $statuses = createStore([]);
//* Events
var activeStrategyChanged = createEvent();
//* Logic
$activeStrategyId.on(activeStrategyChanged, function (_, id) { return id; });
sample({
    clock: $$customStrategies.done,
    filter: function (items) { return !!items.length; },
    fn: function (items) { return items[0].custom_strategy_id; },
    target: activeStrategyChanged,
});
setupUnit($activeStrategyId, activeStrategyChanged, $strategy, strategyCache, getStrategy);
setupUnit($activeStrategyId, activeStrategyChanged, $steps, stepsCache, getSteps);
setupUnit($activeStrategyId, activeStrategyChanged, $stepResults, stepResultsCache, getStepResults);
setupUnit($activeStrategyId, activeStrategyChanged, $statuses, statusesCache, getStatuses);
export var $$activeStrategy = {
    requestes: {
        strategy: getStrategy,
        steps: getSteps,
        stepResults: getStepResults,
        statuses: getStatuses,
    },
    $activeStrategyId: $activeStrategyId,
    $strategy: $strategy,
    $steps: $steps,
    $stepResults: $stepResults,
    $statuses: $statuses,
    $strategyStatus: getStrategy.$status,
    $stepsStatus: getSteps.$status,
    $stepResultsStatus: getStepResults.$status,
    $statusesStatus: getStatuses.$status,
    activeStrategyChanged: activeStrategyChanged,
};
//* Utils
function setupUnit($id, trigger, $unit, cache, request) {
    sample({
        clock: trigger,
        source: cache.$cache,
        filter: function (map, params) { return cache.hasItem(map, params); },
        fn: function (map, params) { return cache.getItem(map, params); },
        target: $unit,
    });
    sample({
        clock: trigger,
        target: request.submited,
    });
    sample({
        clock: request.done,
        target: $unit,
    });
    sample({
        clock: request.done,
        source: $id,
        fn: function (key, item) { return ({ key: key, item: item }); },
        target: cache.cacheAdded,
    });
}
