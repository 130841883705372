import { $$alerts } from '../alerts';
import { contact } from '@/shared/api';
import { myMoment } from '@/shared/tools';
import { attach, createEvent, createStore, sample } from 'effector';
import moment from 'moment';
var updateFx = attach({ effect: contact.getReports });
var $strategyId = createStore(null);
var $value = createStore([]);
var $endDate = createStore(moment().format('YYYY-MM-DD'));
var $startDate = createStore(moment().subtract(1, 'months').format('YYYY-MM-DD'));
var strategyIdChanged = createEvent();
var startDateChanged = createEvent();
var endDateChanged = createEvent();
$strategyId.on(strategyIdChanged, function (_, strategyId) { return strategyId; });
$value.on(updateFx.doneData, function (_, payload) { return payload; });
$startDate.on(startDateChanged, function (_, value) {
    return value ? myMoment(value).format('YYYY-MM-DD') : myMoment().format('YYYY-MM-DD');
});
$endDate.on(endDateChanged, function (_, value) {
    return value ? myMoment(value).format('YYYY-MM-DD') : myMoment().format('YYYY-MM-DD');
});
var $pending = updateFx.pending;
sample({
    clock: strategyIdChanged,
    source: {
        strategyId: $strategyId,
        startDate: $startDate,
        endDate: $endDate,
    },
    filter: function (strategyId) { return !!strategyId; },
    fn: function (contact) { return ({
        custom_strategy_id: contact.strategyId,
        startDate: contact.startDate,
        endDate: contact.endDate,
    }); },
    target: updateFx,
});
sample({
    clock: endDateChanged,
    source: {
        strategyId: $strategyId,
        startDate: $startDate,
        endDate: $endDate,
    },
    filter: function (strategyId) { return !!strategyId; },
    fn: function (contact) { return ({
        custom_strategy_id: contact.strategyId,
        startDate: contact.startDate,
        endDate: contact.endDate,
    }); },
    target: updateFx,
});
sample({
    clock: startDateChanged,
    source: {
        strategyId: $strategyId,
        startDate: $startDate,
        endDate: $endDate,
    },
    filter: function (strategyId) { return !!strategyId; },
    fn: function (contact) { return ({
        custom_strategy_id: contact.strategyId,
        startDate: contact.startDate,
        endDate: contact.endDate,
    }); },
    target: updateFx,
});
sample({
    clock: updateFx.fail,
    fn: function () { return 'Ошибка'; },
    target: $$alerts.showDanger,
});
export var $$ContactStats = {
    $strategyId: $strategyId,
    strategyIdChanged: strategyIdChanged,
    $value: $value,
    $pending: $pending,
    $startDate: $startDate,
    $endDate: $endDate,
    startDateChanged: startDateChanged,
    endDateChanged: endDateChanged,
};
