import { finance } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getHistoryFx = attach({ effect: finance.getHistory });
var $items = createStore([]);
var $pending = getHistoryFx.pending;
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: submited,
    source: $pending,
    filter: function (pending) { return !pending; },
    target: getHistoryFx,
});
// Done
sample({
    clock: getHistoryFx.doneData,
    fn: function (_a) {
        var history = _a.history;
        return history;
    },
    target: $items,
});
export var $$balanceHistory = {
    $items: $items,
    $pending: $pending,
    submited: submited,
};
