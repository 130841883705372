import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { ChevronDownIcon, ChevronUpIcon } from '@/shared/assets';
import classNames from 'classnames';
import { memo } from 'react';
var getColor = function (active) {
    return active ? 'var(--text-color-three)' : 'var(--nav-list-disable)';
};
export var SortButton = function (_a) {
    var className = _a.className, sort = _a.sort;
    // Variables
    var ClassName = classNames('sort-button', className);
    // Render
    var renderUp = function () { return (_jsx(ChevronUpIcon, { className: "sort-button__up", color: getColor(sort === 'ASC') })); };
    var renderDown = function () { return (_jsx(ChevronDownIcon, { className: "sort-button__down", color: getColor(sort === 'DESC') })); };
    return (_jsxs("div", { className: ClassName, children: [renderUp(), renderDown()] }));
};
export var MemoSortButton = memo(SortButton);
