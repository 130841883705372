import { contact } from '@/shared/api';
import { $$alerts, $$contacts } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
var updateFx = attach({ effect: contact.updateAvatar });
var $avatar = createStore(null);
var $contactId = createStore(null);
var avatarChanged = createEvent();
var contactIdChanged = createEvent();
$avatar.on(avatarChanged, function (_, avatar) { return avatar; });
$contactId.on(contactIdChanged, function (_, contactId) { return contactId; });
var $pending = updateFx.pending;
var $error = createStore(null);
var submited = createEvent();
var reseted = createEvent();
// sample({
//   clock: contactIdChanged,
//   source: $$activeContact.$item,
//   fn: (contact) => ({ avatar: contact.avatar }),
//   target: $avatar
// })
// Submited
sample({
    clock: submited,
    source: {
        avatar: $avatar,
        contact_id: $contactId,
    },
    filter: function (_a) {
        var avatar = _a.avatar;
        return !!avatar;
    },
    fn: function (contact) { return ({ avatar: contact.avatar, contact_id: contact.contact_id }); },
    target: updateFx,
});
// Done
sample({
    clock: updateFx.done,
    fn: function () { return 'Аватар изменен'; },
    target: $$alerts.showSuccess,
});
sample({
    clock: updateFx.done,
    target: $$contacts.reload,
});
// Failed
sample({
    clock: updateFx.fail,
    fn: function () { return 'Аватар не изменен'; },
    target: $$alerts.showDanger,
});
// Reseted
reset({
    clock: reseted,
    target: [$avatar],
});
reset({
    clock: [],
    target: $error,
});
export var $$contactAvatar = {
    $avatar: $avatar,
    avatarChanged: avatarChanged,
    $contactId: $contactId,
    contactIdChanged: contactIdChanged,
    $error: $error,
    $pending: $pending,
    submited: submited,
    reseted: reseted,
};
