import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
export var CloudCustom = function (_a) {
    var elementRef = _a.elementRef, contentRef = _a.contentRef, className = _a.className, headerClassName = _a.headerClassName, contentClassName = _a.contentClassName, style = _a.style, noPaddingContent = _a.noPaddingContent, header = _a.header, children = _a.children;
    // Variables
    var ClassName = classNames('cloudd', className);
    var HeaderClassName = classNames('cloud__header', headerClassName);
    var ContentClassName = classNames('cloud__content', { 'cloud__content--no-padding': noPaddingContent }, contentClassName);
    return (_jsxs("div", { ref: elementRef, className: ClassName, style: style, children: [header && (_jsxs("div", { className: HeaderClassName, children: [_jsxs("div", { className: "cloud__header-left", children: [_jsx("div", { className: "cloud__title", children: header.title }), header.subtitle && _jsx("div", { className: "cloud__subtitle", children: header.subtitle })] }), _jsx("div", { className: "cloud__header-right", children: header.right })] })), _jsx("div", { ref: contentRef, className: ContentClassName, children: children })] }));
};
