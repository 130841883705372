import { getAll } from './_get-all';
import { getOne } from './_get-one';
import { reset } from './_reset';
import { update } from './_update';
export var customStrategy = {
    getAll: getAll,
    getOne: getOne,
    update: update,
    reset: reset,
};
