import { contact } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
var getFx = attach({ effect: contact.getCount });
var $count = createStore(0);
var $pending = getFx.pending;
var submited = createEvent();
var reload = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: submited,
});
// Submited
sample({
    clock: [submited, reload],
    target: getFx,
});
// Done
sample({
    clock: getFx.doneData,
    fn: function (_a) {
        var count = _a.count;
        return count;
    },
    target: $count,
});
export var $$contactCount = {
    $count: $count,
    $pending: $pending,
    submited: submited,
    reload: reload,
    done: getFx.done,
};
