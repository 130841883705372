import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useMemo, useState } from 'react';
var initialState = {
    isOpen: false,
    change: function () { return null; },
    toggle: function () { return null; },
    open: function () { return null; },
    close: function () { return null; },
};
export var SidebarContext = createContext(initialState);
export var SidebarProvider = function (_a) {
    var children = _a.children;
    // State
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    // Handlers
    var handleChange = useCallback(function (value) { return setIsOpen(value); }, []);
    var handleToggle = useCallback(function () { return setIsOpen(function (prev) { return !prev; }); }, []);
    var handleOpen = useCallback(function () { return setIsOpen(true); }, []);
    var handleClose = useCallback(function () { return setIsOpen(false); }, []);
    // Memo
    var value = useMemo(function () { return ({
        isOpen: isOpen,
        change: handleChange,
        toggle: handleToggle,
        open: handleOpen,
        close: handleClose,
    }); }, [handleChange, handleClose, handleOpen, handleToggle, isOpen]);
    return _jsx(SidebarContext.Provider, { value: value, children: children });
};
