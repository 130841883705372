import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// Кнопка Отправить
import { $$contactCommunication } from '../../model';
import { MemoCommunicationModal } from '../communication-modal';
import './style.scss';
import { MemoButton, MemoSkeletonButton } from '@/shared/components';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo, useCallback, useState } from 'react';
import { $isModalOpen, openModal, closeModal } from '@/shared/effector/communication/modal-status';
export var OpenCommunicationModalButton = function (_a) {
    var className = _a.className;
    // Effector
    var pending = useUnit($$contactCommunication.info.$infoPending);
    var _b = useUnit([$isModalOpen, openModal, closeModal]), isOpenModal = _b[0], open = _b[1], close = _b[2];
    // State
    var _c = useState(false), show = _c[0], setShow = _c[1];
    // Variables
    var ClassName = classNames('open-communication-modal-button', className);
    // Handlers
    var handleOpen = useCallback(function () {
        // setShow(true)
        open();
    }, []);
    var handleClose = useCallback(function () { return setShow(false); }, []);
    // Render
    if (pending) {
        return _jsx(MemoSkeletonButton, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(MemoButton, { className: ClassName, onClick: handleOpen, children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C" }), _jsx(MemoCommunicationModal, { show: show, onHide: handleClose })] }));
};
export var MemoOpenCommunicationModalButton = memo(OpenCommunicationModalButton);
