import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var Head = function (_a) {
    var className = _a.className, columns = _a.columns, showEdit = _a.showEdit;
    // Variables
    var ClassName = classNames('head', className);
    return (_jsxs("div", { className: ClassName, children: [columns.map(function (_a, index) {
                var title = _a.title;
                return (_jsx("div", { className: "head__item", children: title }, index));
            }), showEdit && _jsx("div", { className: "head__item head__item--edit", children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" })] }));
};
export var MemoHead = memo(Head);
