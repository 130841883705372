import { auth } from '@/shared/api';
import { $$alerts } from '@/shared/effector';
import { routes } from '@/shared/router';
import { redirect } from 'atomic-router';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import { delay, reset } from 'patronum';
var loginFx = attach({ effect: auth.login });
var $email = createStore('');
var $password = createStore('');
var $pending = loginFx.pending;
var $error = createStore('');
var emailChanged = createEvent();
var passwordChanged = createEvent();
var submited = createEvent();
var reseted = createEvent();
var loginedFx = createEffect(function () {
    localStorage.setItem('is-auth', 'true');
});
$email.on(emailChanged, function (_, value) { return value; });
$password.on(passwordChanged, function (_, value) { return value; });
// Submited
sample({
    clock: submited,
    source: { email: $email, password: $password },
    target: loginFx,
});
// Done
var delayedDone = delay({
    source: loginedFx.done,
    timeout: 300,
});
sample({
    clock: loginFx.done,
    target: loginedFx,
});
redirect({
    clock: delayedDone,
    route: routes.account.main,
});
// Fail
sample({
    clock: loginFx.failData,
    target: [$error, $$alerts.showDanger],
});
sample({
    clock: [emailChanged, passwordChanged],
    source: $error,
    filter: function (error) { return !!error; },
    fn: function () { return null; },
    target: $error,
});
reset({ clock: reseted, target: [$email, $password, $error] });
export var $$loginForm = {
    $email: $email,
    $password: $password,
    $pending: $pending,
    $error: $error,
    emailChanged: emailChanged,
    passwordChanged: passwordChanged,
    reseted: reseted,
    submited: submited,
    done: loginFx.done,
    fail: loginFx.fail,
};
