import { $$app } from '../effector';
import { sample } from 'effector';
export function PageTitleFactory(_a) {
    var title = _a.title, route = _a.route;
    sample({
        clock: route.opened,
        fn: function () { return title; },
        target: $$app.pageTitleChanged,
    });
}
