import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Блок История Коммуникаций
import './style.scss';
import { MemoActiveStrategySelect } from '@/features/strategy';
import { PageLayout } from '@/shared/components';
import { MemoInput } from '@/shared/components/form/components';
import { $$activeStrategy } from '@/shared/effector';
import { $$ContactStats } from '@/shared/effector/contact/contact-stats';
import { useApexAreaOptions } from '@/shared/hooks/charts/use-apex-area-options';
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import ReactApexChart from 'react-apexcharts';
export var ContactsStats = function () {
    var strategyId = useUnit([$$activeStrategy.$activeStrategyId])[0];
    var _a = useUnit([
        $$ContactStats.strategyIdChanged,
        $$ContactStats.$value,
        $$ContactStats.$endDate,
        $$ContactStats.endDateChanged,
        $$ContactStats.$startDate,
        $$ContactStats.startDateChanged,
    ]), strategyIdChanged = _a[0], value = _a[1], endDate = _a[2], endDateChanged = _a[3], startDate = _a[4], startDateChanged = _a[5];
    var options = useApexAreaOptions('datetime');
    useEffect(function () {
        strategyIdChanged(strategyId);
    }, [strategyId, strategyIdChanged]);
    return (_jsx(PageLayout.Cloud, { contentClassName: "contacts-stats", header: {
            title: 'Статистика Коммуникаций',
            subtitle: 'Показана статистика за последний месяц',
            right: (_jsxs("div", { className: "contacts-stats__right-wrapper", children: [_jsxs("div", { className: "right-wrapper", children: [_jsx("p", { children: "\u041D\u0430\u0447\u0430\u043B\u043E:" }), _jsx(MemoInput, { type: "date", name: "start-date", value: startDate, onChange: startDateChanged })] }), _jsxs("div", { className: "right-wrapper", children: [_jsx("p", { children: "\u041A\u043E\u043D\u0435\u0446:" }), _jsx(MemoInput, { type: "date", name: "end-date", className: 'end-date-input', value: endDate, onChange: endDateChanged })] }), _jsx(MemoActiveStrategySelect, { className: "sales-funnel__select" })] })),
        }, children: _jsx("div", { className: 'stats-date', children: _jsx(ReactApexChart, { type: "area", series: [
                    {
                        data: value === null || value === void 0 ? void 0 : value.map(function (_a) {
                            var date = _a.date, count = _a.count;
                            return ({
                                x: date,
                                y: count,
                            });
                        }),
                    },
                ], options: options.options, height: 260 }) }) }));
};
