import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { LevelUpContactButton } from '@/features/contact';
import { MemoActiveStrategySelect } from '@/features/strategy';
import { PageLayout } from '@/shared/components';
import classNames from 'classnames';
import { memo } from 'react';
export var ChooseContactStrategy = function (_a) {
    // Effector
    var className = _a.className;
    // Variables
    var ClassName = classNames('choose-contact-strategy', className);
    return (_jsxs(PageLayout.Cloud, { className: ClassName, header: { title: 'Выберите стратегию' }, children: [_jsx(MemoActiveStrategySelect, { className: "choose-contact-strategy__select" }), _jsx("div", { className: "choose-contact-strategy__status-title", children: "\u0422\u0435\u043A\u0443\u0449\u0438\u0439 \u0441\u0442\u0430\u0442\u0443\u0441 \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0430" }), _jsx("div", { className: "choose-contact-strategy__buttons", children: _jsx(LevelUpContactButton, {}) })] }));
};
export var MemoChooseContactStrategy = memo(ChooseContactStrategy);
