import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
export var SimpleColorPicker = function (_a) {
    var className = _a.className, colors = _a.colors, pending = _a.pending, value = _a.value, onChange = _a.onChange;
    // Variables
    var ClassName = classNames('simple-color-picker', className);
    // Render
    var renderItems = function () {
        if (pending) {
            return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(function (item) { return (_jsx(Skeleton, { containerClassName: classNames('simple-color-picker__item', 'simple-color-picker__item--skeleton') }, item)); });
        }
        return colors.map(function (_a) {
            var innerValue = _a.value, color = _a.color;
            return (_jsx("div", { className: classNames('simple-color-picker__item', value === innerValue && 'simple-color-picker__item--active'), style: { backgroundColor: color }, onClick: function () { return onChange(innerValue); } }, innerValue));
        });
    };
    return (_jsxs("div", { className: ClassName, children: [_jsx("div", { className: "simple-color-picker__title", children: "\u0426\u0432\u0435\u0442\u0430" }), _jsx("div", { className: "simple-color-picker__items", children: renderItems() })] }));
};
export var MemoSimpleColorPicker = memo(SimpleColorPicker);
