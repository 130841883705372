var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo, useCallback, useId, useMemo } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
export var Select = function (_a) {
    var className = _a.className, name = _a.name, disabled = _a.disabled, label = _a.label, caption = _a.caption, _b = _a.size, size = _b === void 0 ? 'lg' : _b, _c = _a.items, items = _c === void 0 ? [] : _c, value = _a.value, onChange = _a.onChange, withNoValue = _a.withNoValue;
    // Hooks
    var id = useId();
    // Variables
    var ClassName = classNames('select', className);
    // Memo
    var memoItems = useMemo(function () { return (withNoValue ? __spreadArray([{ value: -1, text: 'Не выбрано' }], items, true) : items); }, [items, withNoValue]);
    // Handlers
    var handleChange = useCallback(function (event) {
        var changedValue = event.target.value;
        onChange === null || onChange === void 0 ? void 0 : onChange(changedValue === '-1' ? null : parseInt(changedValue) || changedValue);
    }, [onChange]);
    return (_jsxs(Form.Group, { className: ClassName, controlId: id, children: [label && _jsx(Form.Label, { children: label }), _jsx(InputGroup, { children: _jsx(Form.Select, { className: "input__input", size: size, name: name, disabled: disabled, value: value || '', onChange: handleChange, children: memoItems.map(function (_a) {
                        var value = _a.value, text = _a.text;
                        return (_jsx("option", { value: value, children: text }, value));
                    }) }) }), caption && _jsx(Form.Text, { children: caption })] }));
};
export var MemoSelect = memo(Select);
