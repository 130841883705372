import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './choose-contact.module.scss';
import { MemoButton, PageLayout } from '@/shared/components';
import BlobImage from '@/assets/landing/images/bl2.gif';
import ContactsIconLignt from "@/assets/account/images/contacts-icon-light.png";
import ContactsIconDark from "@/assets/account/images/contacts-icon-dark.png";
import { useTheme } from '@/shared/theme';
export var ChooseContact = function () {
    var theme = useTheme().theme;
    var src = theme === 'light' ? ContactsIconLignt : ContactsIconDark;
    return (_jsx("div", { className: styles.mainContainer, children: _jsx(PageLayout.Cloud, { contentClassName: "contacts-stats", header: {
                title: 'Последний контакт',
                subtitle: 'С этим человеком вы недавно общались',
                right: (_jsx("div", { className: styles.contactsButtonContainer, children: _jsx("button", { className: styles.contactsButton, children: _jsx("div", { className: styles.contactsButtonDiv, children: _jsx("img", { src: src, alt: "contacts-icon" }) }) }) }))
            }, children: _jsxs("div", { className: styles.content, children: [_jsx("div", { className: styles.blobConstrainer, children: _jsx("img", { className: styles.chooseContactBlob, src: BlobImage, alt: "blob" }) }), _jsxs("div", { className: styles.right, children: [_jsx("div", { children: "\u0414\u043B\u044F \u043D\u0430\u0447\u0430\u043B\u0430 \u0440\u0430\u0431\u043E\u0442\u044B \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u043E\u043D\u0442\u0430\u043A\u0442" }), _jsx("div", { className: styles.chooseContactButton, children: _jsx(MemoButton, { children: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u043A\u043E\u043D\u0442\u0430\u043A\u0442" }) })] })] }) }) }));
};
