import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { EyeHideIcon, EyeShowIcon } from '@/shared/assets';
import { useTelephoneMask } from '@/shared/hooks';
import classNames from 'classnames';
import { memo, useCallback, useId, useMemo, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
export var Input = function (_a) {
    var className = _a.className, type = _a.type, name = _a.name, readOnly = _a.readOnly, disabled = _a.disabled, rows = _a.rows, label = _a.label, placeholder = _a.placeholder, caption = _a.caption, _b = _a.size, size = _b === void 0 ? 'lg' : _b, value = _a.value, onChange = _a.onChange;
    // Hooks
    var id = useId();
    var _c = useTelephoneMask(value || '', type === 'telephone' ? onChange : null), maskedValue = _c.maskedValue, handleTelephoneChange = _c.handleChange, handleKeyDown = _c.handleKeyDown;
    // State
    var _d = useState(false), isShow = _d[0], setIsShow = _d[1];
    // Variables
    var ClassName = classNames('my-input', className);
    var validValue = type === 'telephone' ? maskedValue : value;
    var ShowStateIcon = !isShow ? EyeShowIcon : EyeHideIcon;
    // Memo
    var validType = useMemo(function () {
        if (type === 'telephone') {
            return 'text';
        }
        if (type === 'password' && isShow) {
            return 'text';
        }
        if (type === 'textarea') {
            return undefined;
        }
        return type;
    }, [isShow, type]);
    // Handlers
    var handleChange = useCallback(function (event) {
        var changedValue = event.target.value;
        if (type === 'telephone') {
            handleTelephoneChange(changedValue);
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(changedValue);
    }, [handleTelephoneChange, onChange, type]);
    var handleIsShowToggled = useCallback(function () { return setIsShow(function (prev) { return !prev; }); }, []);
    return (_jsxs(Form.Group, { className: ClassName, controlId: id, children: [label && _jsx(Form.Label, { children: label }), _jsxs(InputGroup, { children: [_jsx(Form.Control, { as: type === 'textarea' ? 'textarea' : undefined, className: "my-input__input", size: size, type: validType, name: name, readOnly: readOnly, disabled: disabled, rows: type === 'textarea' ? rows || 3 : undefined, placeholder: placeholder, value: validValue, onKeyDown: type === 'telephone' ? handleKeyDown : undefined, onChange: handleChange }), type === 'password' && (_jsx(Button, { variant: "outline-secondary", onClick: handleIsShowToggled, children: _jsx(ShowStateIcon, { width: 24, height: 24 }) }))] }), caption && _jsx(Form.Text, { children: caption })] }));
};
export var MemoInput = memo(Input);
