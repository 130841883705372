import { contact } from '@/shared/api';
import { $$alerts, $$contactCount, $$contacts } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
var updateFx = attach({ effect: contact.deleteContact });
var $contactId = createStore(null);
var contactIdChanged = createEvent();
$contactId.on(contactIdChanged, function (_, contactId) { return contactId; });
var $pending = updateFx.pending;
var $error = createStore(null);
var submited = createEvent();
var reseted = createEvent();
// Submited
sample({
    clock: submited,
    source: {
        contact_id: $contactId,
    },
    filter: function (_a) {
        var contact_id = _a.contact_id;
        return !!contact_id;
    },
    fn: function (contact) { return ({ contact_id: contact.contact_id }); },
    target: updateFx,
});
// Done
sample({
    clock: updateFx.done,
    fn: function () { return 'Контакт удален'; },
    target: $$alerts.showSuccess,
});
sample({
    clock: updateFx.done,
    target: $$contacts.reload,
});
sample({
    clock: updateFx.done,
    target: $$contactCount.reload,
});
// Reseted
reset({
    clock: reseted,
    target: [$contactId],
});
reset({
    clock: [],
    target: $error,
});
export var $$contactDelete = {
    $contactId: $contactId,
    contactIdChanged: contactIdChanged,
    $error: $error,
    $pending: $pending,
    submited: submited,
    reseted: reseted,
};
