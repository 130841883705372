var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createEvent, createStore } from 'effector';
import { debug, reset } from 'patronum';
export function CacheFactory(_a) {
    var name = _a.name, reseted = _a.reset, useDebug = _a.useDebug;
    var getName = function (unit) { return "cache ".concat(name, " ").concat(unit); };
    //* Stores
    var $cache = createStore({}, {
        name: getName('map'),
    });
    //* Events
    var cacheAdded = createEvent(getName('added'));
    var cacheUpdated = createEvent(getName('updated'));
    var cacheRemoved = createEvent(getName('removed'));
    var cacheReseted = createEvent(getName('reseted'));
    //* Logic
    $cache.on(cacheAdded, function (items, _a) {
        var _b;
        var key = _a.key, item = _a.item;
        return (__assign(__assign({}, items), (_b = {}, _b[key] = item, _b)));
    });
    $cache.on(cacheUpdated, function (items, _a) {
        var _b;
        var key = _a.key, item = _a.item;
        return (__assign(__assign({}, items), (_b = {}, _b[key] = item, _b)));
    });
    $cache.on(cacheRemoved, function (items, _a) {
        var key = _a.key;
        var newItems = __assign({}, items);
        delete newItems[key];
        return newItems;
    });
    reset({
        clock: [cacheReseted, reseted].filter(Boolean),
        target: [$cache],
    });
    if (useDebug) {
        debug($cache, cacheAdded, cacheUpdated, cacheRemoved, cacheReseted);
    }
    //* Utils
    var hasItem = function (items, key) { return key in items; };
    var getItem = function (items, key) { var _a; return (_a = items[key]) !== null && _a !== void 0 ? _a : null; };
    return {
        $cache: $cache,
        cacheAdded: cacheAdded,
        cacheUpdated: cacheUpdated,
        cacheRemoved: cacheRemoved,
        cacheReseted: cacheReseted,
        hasItem: hasItem,
        getItem: getItem,
    };
}
