import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useUnit } from "effector-react";
import React, { useEffect, useState } from "react";
import { ContactSidebarIcon } from "@/shared/assets";
import "./styles.scss";
import { Blob } from '../blob/ui';
import { Button } from "../buttons";
import { $isModalOpen, closeModal } from "@/shared/effector/profile/modal-welcome-status";
import { $$session } from "@/shared/effector";
import { useMediaQuery } from "@/shared/hooks";
export var ModalWelcome = function (_a) {
    var _b = useState(0), step = _b[0], setStep = _b[1];
    var _c = useUnit([$isModalOpen, closeModal]), isOpenModal = _c[0], close = _c[1];
    var auth = useUnit($$session.$auth);
    var isDesktop = useMediaQuery('screen and (min-width: 1201px)');
    var handleClickButton = function () {
        localStorage.setItem('is-first-loading', false.toString());
        setStep(2);
    };
    useEffect(function () {
        // console.log("auth в сет таймаут", auth)
        var timer;
        if (auth === 2) {
            timer = setTimeout(function () {
                setStep(1);
            }, 2000);
        }
        return function () { return clearTimeout(timer); };
    }, [auth]);
    return (_jsx(_Fragment, { children: auth === 2 ? (_jsxs(_Fragment, { children: [step === 1 && (_jsx("div", { className: isOpenModal ? 'modall open' : 'modall close', onClick: close, children: _jsxs("div", { className: "modal-content", onClick: function (e) {
                            e.stopPropagation();
                        }, children: [_jsx(Blob, { image: 'BlobWebpBlue', className: 'blob' }), _jsxs("p", { className: "title-welcome", children: ["\u0414\u043E\u0431\u0440\u043E \u043F\u043E\u0436\u0430\u043B\u043E\u0432\u0430\u0442\u044C \u0432 \u043B\u0438\u0447\u043D\u044B\u0439 \u043A\u0430\u0431\u0438\u043D\u0435\u0442 PRM. ", _jsx("br", {}), " \u0414\u0430\u0432\u0430\u0439\u0442\u0435 \u043D\u0430\u0447\u043D\u0451\u043C!"] }), _jsx(Button, { className: "modal-content-button-welcome", variant: "primary", onClick: handleClickButton, children: "\u041D\u0430\u0447\u0430\u0442\u044C" })] }) })), step === 2 && isDesktop && (_jsxs(_Fragment, { children: [isOpenModal && (_jsxs("div", { className: 'modal-content-welcome', children: [_jsx(ContactSidebarIcon, { className: "sidebar-nav-item__icon" }), _jsx("div", { className: "sidebar-nav-item__title", children: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B" }), _jsx("div", { className: 'circle-outer' }), _jsx("div", { className: 'circle-inner' })] })), _jsx("div", { className: isOpenModal ? 'modall open' : 'modall close', onClick: close, children: _jsxs("div", { className: "modal-content", onClick: function (e) {
                                    e.stopPropagation();
                                }, children: [_jsx(Blob, { image: 'BlobWebpBlue', className: 'blob' }), _jsx("p", { className: "title-welcome-stepTwo", children: "\u0414\u043B\u044F \u043D\u0430\u0447\u0430\u043B\u0430 \u0440\u0430\u0431\u043E\u0442\u044B \u043F\u0435\u0440\u0435\u0439\u0434\u0438\u0442\u0435 \u0432 \u0440\u0430\u0437\u0434\u0435\u043B \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u044B" })] }) })] }))] })) : null }));
};
