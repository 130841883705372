import { jsx as _jsx } from "react/jsx-runtime";
import { SortButton } from './../components/sort-button/ui';
import { useSort } from '@table-library/react-table-library/sort';
function onSortChange() { }
export var useTableSort = function (data, sortFns, onChange) {
    if (onChange === void 0) { onChange = onSortChange; }
    return useSort(data, {
        onChange: onChange,
    }, {
        sortIcon: {
            iconDefault: _jsx(SortButton, {}),
            iconUp: _jsx(SortButton, { sort: "ASC" }),
            iconDown: _jsx(SortButton, { sort: "DESC" }),
        },
        sortFns: sortFns,
    });
};
