import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { Link } from 'atomic-router-react';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
export var Button = function (_a) {
    var className = _a.className, _b = _a.type, type = _b === void 0 ? 'submit' : _b, _c = _a.variant, variant = _c === void 0 ? 'primary' : _c, reverse = _a.reverse, circle = _a.circle, disabled = _a.disabled, children = _a.children, onClick = _a.onClick, to = _a.to, params = _a.params;
    // Variables
    var ClassName = classNames('button', "button--".concat(variant), reverse && 'button--reverse', circle && 'button--circle', disabled && 'button--disabled', className);
    var Component = to ? Link : 'button';
    // Handlers
    var handleClick = useCallback(function () {
        // e.preventDefault()
        onClick === null || onClick === void 0 ? void 0 : onClick();
    }, [onClick]);
    return (_jsx(Component, { type: type, className: ClassName, onClick: handleClick, to: to, params: params, disabled: disabled, children: children }));
};
export var MemoButton = memo(Button);
