import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ContactCloud } from '@/entities/contact';
import { MemoConnectContactButton, MemoEditContactButton, MemoLevelUpContactButton, } from '@/features/contact';
import { MemoActiveStrategySelect } from '@/features/strategy';
import { $$lastContact } from '@/shared/effector';
import { useUnit } from 'effector-react';
export var LastContact = function (_a) {
    // Effector
    var isSelect = _a.isSelect;
    var contact = useUnit($$lastContact.$item);
    if (!contact) {
        return null;
    }
    return (_jsx(ContactCloud, { title: "\u041F\u043E\u0441\u043B\u0435\u0434\u043D\u0438\u0439 \u043A\u043E\u043D\u0442\u0430\u043A\u0442", subtitle: "\u0421 \u044D\u0442\u0438\u043C \u0447\u0435\u043B\u043E\u0432\u0435\u043A\u043E\u043C \u0432\u044B \u043D\u0435\u0434\u0430\u0432\u043D\u043E \u043E\u0431\u0449\u0430\u043B\u0438\u0441\u044C", contact: contact, strategySelectSlot: isSelect ? _jsx(MemoActiveStrategySelect, {}) : false, buttonsSlot: _jsxs(_Fragment, { children: [_jsx(MemoConnectContactButton, { contact: contact }), _jsx(MemoLevelUpContactButton, { contact: contact }), _jsx(MemoEditContactButton, { contact: contact })] }) }));
};
