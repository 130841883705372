import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useLayoutEffect, useMemo, useState, } from 'react';
var initialState = {
    theme: 'light',
    change: function () { return null; },
    toggle: function () { return null; },
};
export var ThemeContext = createContext(initialState);
export var ThemeProvider = function (_a) {
    var _b;
    var children = _a.children;
    // State
    var _c = useState((_b = localStorage.getItem('theme')) !== null && _b !== void 0 ? _b : 'light'), theme = _c[0], setTheme = _c[1];
    // Handlers
    var handleChange = useCallback(function (value) {
        localStorage.setItem('theme', value);
        document.documentElement.setAttribute('data-theme', value);
        setTheme(value);
    }, []);
    var handleToggle = useCallback(function () {
        setTheme(function (prev) {
            var value = prev === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', value);
            document.documentElement.setAttribute('data-theme', value);
            return value;
        });
    }, []);
    // Effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(function () { var _a; return handleChange((_a = localStorage.getItem('theme')) !== null && _a !== void 0 ? _a : 'light'); }, []);
    // Memo
    var value = useMemo(function () { return ({ theme: theme, change: handleChange, toggle: handleToggle }); }, [handleChange, handleToggle, theme]);
    return _jsx(ThemeContext.Provider, { value: value, children: children });
};
