var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { customStrategy } from '@/shared/api';
import { $$activeStrategy, $$alerts, $$customStrategies } from '@/shared/effector';
import { RequestFactory } from '@/shared/factories';
import { combine, createEvent, createStore, sample } from 'effector';
import { spread } from 'patronum';
var update = RequestFactory({
    name: 'UPDATE_CUSTOM_STRATEGY',
    api: customStrategy.update,
});
var $strategy = $$activeStrategy.$strategy;
var $name = createStore('');
var $description = createStore('');
var $canSubmit = combine($name, function (name) { return !!name; });
var opened = createEvent();
var nameChanged = createEvent();
var descriptionChanged = createEvent();
var submited = createEvent();
$name.on(nameChanged, function (_, value) { return value; });
$description.on(descriptionChanged, function (_, value) { return value; });
sample({
    clock: [$strategy.updates, opened],
    source: $strategy,
    filter: function (strategy) { return Boolean(strategy); },
    target: spread({
        targets: {
            name: nameChanged,
            description: descriptionChanged,
        },
    }),
});
sample({
    clock: submited,
    source: {
        strategy: $strategy,
        name: $name,
        description: $description,
    },
    filter: $canSubmit,
    fn: function (_a) {
        var strategy = _a.strategy, name = _a.name, description = _a.description;
        return ({
            custom_strategy_id: strategy.custom_strategy_id,
            language_id: strategy.language_id,
            name: name,
            description: description,
            image: strategy.image,
        });
    },
    target: update.submited,
});
sample({
    clock: update.done,
    fn: function (strategy) { return strategy.custom_strategy_id; },
    target: [$$activeStrategy.requestes.strategy.submited, $$customStrategies.submited],
});
sample({
    clock: update.done,
    fn: function () { return 'Изменения сохранены'; },
    target: $$alerts.showSuccess,
});
export var $$editStrategyModal = __assign(__assign({}, update), { $name: $name, $description: $description, $canSubmit: $canSubmit, opened: opened, nameChanged: nameChanged, descriptionChanged: descriptionChanged, submited: submited });
