import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var HorizontalScrollableItems = function (_a) {
    var className = _a.className, title = _a.title, rightHeaderSlot = _a.rightHeaderSlot, items = _a.items, renderItem = _a.renderItem;
    // Variables
    var ClassName = classNames('horizontal-scrollable-items', className);
    return (_jsxs("div", { className: ClassName, children: [_jsxs("div", { className: "horizontal-scrollable-items__header", children: [_jsx("div", { className: "horizontal-scrollable-items__left", children: _jsx("div", { className: "horizontal-scrollable-items__title", children: title }) }), rightHeaderSlot && (_jsx("div", { className: "horizontal-scrollable-items__right", children: rightHeaderSlot }))] }), _jsx("div", { className: "horizontal-scrollable-items__cotnent", children: _jsx("div", { className: "horizontal-scrollable-items__items", children: items.map(renderItem) }) })] }));
};
export var MemoHorizontalScrollableItems = memo(HorizontalScrollableItems);
