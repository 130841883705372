var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { account } from './account';
import { auth } from './auth';
import { LandingRoute } from './landing-page';
import { LandingRefRoute } from './landing-ref-page';
import { createRoutesView } from 'atomic-router-react';
export var Pages = createRoutesView({
    routes: __spreadArray(__spreadArray([LandingRoute, LandingRefRoute], auth, true), account, true),
});
