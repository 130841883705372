import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var SmallWidget = function (_a) {
    var className = _a.className, Icon = _a.Icon, title = _a.title, value = _a.value;
    // Variables
    var ClassName = classNames('small-widget', className);
    return (_jsxs("div", { className: ClassName, children: [_jsx("div", { className: "small-widget__icon", children: _jsx(Icon, { width: 32, height: 32 }) }), _jsxs("div", { className: "small-widget__text", children: [_jsx("div", { className: "small-widget__title", children: title }), _jsx("div", { className: "small-widget__value", children: value })] })] }));
};
export var MemoSmallWidget = memo(SmallWidget);
