import { activatePromocodeFx } from "@/shared/api/promocode";
import { attach, createEvent, createStore, sample } from "effector";
import { $$alerts } from "../alerts";
var activateFx = attach({ effect: activatePromocodeFx });
var $value = createStore('');
var valueChanged = createEvent();
var submited = createEvent();
$value.on(valueChanged, function (_, value) { return value; });
sample({
    clock: submited,
    source: {
        promocode: $value // передаем параметр для activateFx
    },
    target: activateFx,
});
sample({
    clock: activateFx.done,
    fn: function () { return 'Промокод принят'; },
    target: $$alerts.showSuccess,
});
sample({
    clock: activateFx.failData,
    fn: function () { return 'Промокод не применился'; },
    target: $$alerts.showDanger,
});
export var $$promocode = {
    activateFx: activateFx,
    $value: $value,
    valueChanged: valueChanged,
    submited: submited
};
