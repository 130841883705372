import { finance } from '@/shared/api';
import { attach, createEvent, createStore, sample } from 'effector';
var updateFx = attach({ effect: finance.postCalculateIncomes });
var $strategyId = createStore(null);
var $value = createStore({ values: [] });
var $spacedValue = createStore(0);
var strategyIdChanged = createEvent();
$strategyId.on(strategyIdChanged, function (_, strategyId) { return strategyId; });
$value.on(updateFx.doneData, function (_, payload) { return payload; });
$spacedValue.on(updateFx.doneData, function (_, payload) {
    return payload.values.reduce(function (sum, number) { return sum + number; }, 0);
});
var $pending = updateFx.pending;
sample({
    clock: strategyIdChanged,
    source: $strategyId,
    filter: function (strategyId) { return !!strategyId; },
    fn: function (strategyId) { return ({ custom_strategy_id: strategyId }); },
    target: updateFx,
});
export var $$PotentialIncome = {
    $strategyId: $strategyId,
    strategyIdChanged: strategyIdChanged,
    $value: $value,
    $spacedValue: $spacedValue,
    $pending: $pending,
};
