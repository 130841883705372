import { createEffect } from "effector";
import { request } from "./request";
export var activatePromocodeFx = createEffect(function (_a) {
    var promocode = _a.promocode;
    return request('promocodes/activate', {
        method: 'POST',
        data: { promocode: promocode }
    });
});
// const activatePromocode = (async (promocode: string) => {
//     return await request<IActivatePromocodeReq, IActivatePromocodeRes>('promocodes/activate', {
//         method: 'POST',
//         data: { promocode: promocode }
//     })
// })
