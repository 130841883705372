import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Blob } from '../blob/ui';
import './styles.scss';
import { ModalCloseIcon } from '@/shared/assets';
import { $$PotentialIncome } from '@/shared/effector';
import { $$ModalIncomeCalculation } from '@/shared/effector/finance/modal-income-calculation';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
var ModalIncomeCalculation = function () {
    var _a = useState(false), copy = _a[0], setCopy = _a[1];
    var value = useUnit([$$PotentialIncome.$value])[0];
    var _b = useUnit([
        $$ModalIncomeCalculation.$isModal,
        $$ModalIncomeCalculation.closeModal,
    ]), isOpenModal = _b[0], closeModalIncomeCalculation = _b[1];
    useEffect(function () {
        if (isOpenModal)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'initial';
    }, [isOpenModal]);
    useEffect(function () {
        if (copy) {
            setTimeout(function () {
                setCopy(false);
            }, 2000);
        }
    }, [copy]);
    return (_jsx("div", { className: isOpenModal ? 'modall open' : 'modall close', onClick: function () { return closeModalIncomeCalculation(); }, children: _jsxs("div", { className: "modal-content", onClick: function (e) {
                e.stopPropagation();
            }, children: [_jsx("div", { onClick: function () { return closeModalIncomeCalculation(); }, className: "icon-close", children: _jsx(ModalCloseIcon, { width: 51, height: 51, className: "icon-modal" }) }), _jsx(Blob, { image: 'BlobWebpBlue' }), _jsx("p", { className: "title", children: "\u041F\u043E\u043B\u043D\u044B\u0439 \u0440\u0430\u0441\u0447\u0435\u0442 \u043F\u043E\u0442\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0433\u043E \u0434\u043E\u0445\u043E\u0434\u0430" }), _jsx("div", { className: "wrapper-income", children: value.values.length &&
                        value.values.map(function (el, i) { return (_jsxs("p", { children: ["\u0423\u0440\u043E\u0432\u0435\u043D\u044C ", i + 1, ": ", _jsxs("span", { children: [el, " $"] })] }, i)); }) })] }) }));
};
export default ModalIncomeCalculation;
