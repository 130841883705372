var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import { delay, spread } from 'patronum';
var TIME_FOR_SHOWING_ALERT = 5000;
// Stores
var $autoIncrement = createStore(1);
var $items = createStore([]);
// Events
var increment = createEvent();
var show = createEvent();
var hide = createEvent();
var delayedHide = delay(hide, TIME_FOR_SHOWING_ALERT);
var hidden = createEvent();
// Effects
var pushAlertFx = createEffect(function (_a) {
    var increment = _a.increment, alerts = _a.alerts, alert = _a.alert;
    return ({
        id: increment,
        alerts: __spreadArray(__spreadArray([], alerts, true), [__assign({ id: increment }, alert)], false),
    });
});
var pushFx = attach({
    source: { increment: $autoIncrement, alerts: $items },
    mapParams: function (alert, _a) {
        var increment = _a.increment, alerts = _a.alerts;
        return ({
            increment: increment,
            alerts: alerts,
            alert: alert,
        });
    },
    effect: pushAlertFx,
});
var hideAlertFx = createEffect(function (_a) {
    var toRemoveId = _a.id, alerts = _a.alerts;
    var index = alerts.findIndex(function (_a) {
        var id = _a.id;
        return id === toRemoveId;
    });
    if (index === -1) {
        return alerts;
    }
    var cloned = __spreadArray([], alerts, true);
    cloned.splice(index, 1);
    return cloned;
});
var hideFx = attach({
    source: { alerts: $items },
    mapParams: function (id, _a) {
        var alerts = _a.alerts;
        return ({
            id: id,
            alerts: alerts,
        });
    },
    effect: hideAlertFx,
});
// Events by Factory
var showPrimary = ShowFactory('primary', pushFx);
var showSecondary = ShowFactory('secondary', pushFx);
var showSuccess = ShowFactory('success', pushFx);
var showDanger = ShowFactory('danger', pushFx);
// Logic
$autoIncrement.on(increment, function (value) { return value + 1; });
// Show
sample({
    clock: show,
    target: pushFx,
});
sample({
    clock: pushFx.doneData,
    target: spread({
        id: hide,
        alerts: $items,
    }),
});
sample({
    clock: pushFx.done,
    target: increment,
});
// Remove
sample({
    clock: [hidden, delayedHide],
    target: hideFx,
});
sample({
    clock: hideFx.doneData,
    target: $items,
});
export var $$alerts = {
    $autoIncrement: $autoIncrement,
    $items: $items,
    show: show,
    showPrimary: showPrimary,
    showSecondary: showSecondary,
    showSuccess: showSuccess,
    showDanger: showDanger,
    hidden: hidden,
};
// Factories
function ShowFactory(variant, pushFx) {
    var show = createEvent();
    sample({
        clock: show,
        fn: function (message) { return ({ message: message, variant: variant }); },
        target: pushFx,
    });
    return show;
}
