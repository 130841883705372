import { $$customStrategies } from '@/shared/effector';
import { $$contactCustomStrategyStatuses } from '@/shared/effector/contact';
import { useStoreMap, useUnit } from 'effector-react';
export var useContactStatus = function (contactId) {
    // Effector
    var statuses = useUnit([$$customStrategies.$status, $$contactCustomStrategyStatuses.$status]);
    var status = useStoreMap({
        store: $$contactCustomStrategyStatuses.$mapStatusToContactId,
        keys: [contactId],
        fn: function (items) {
            return (contactId in items ? items[contactId] : null);
        },
    });
    // Variables
    var pending = statuses.some(function (status) { return status === 'pending'; });
    return {
        status: status,
        pending: pending,
    };
};
