import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MemoButton, Button } from '@/shared/components';
import { $isModalOpen, openModal, closeModal } from '@/shared/effector/contact/modal-сhooseStatus';
import { useUnit } from 'effector-react';
import { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { StatusSelect } from '../status-select';
export var LevelUpContactButton = function (_a) {
    // return null
    // console.log("contactcontactcontact", contact)
    var className = _a.className, contact = _a.contact;
    var _b = useUnit([$isModalOpen, openModal, closeModal]), isOpenModal = _b[0], open = _b[1], close = _b[2];
    return (_jsxs(_Fragment, { children: [_jsx(MemoButton, { className: className, onClick: open, children: "Level UP" }), _jsxs(Modal, { show: isOpenModal, onHide: close, children: [_jsx(Modal.Header, { children: _jsx(Modal.Title, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0442\u0430\u0442\u0443\u0441" }) }), _jsx(Modal.Body, { children: _jsx(StatusSelect, { contact: contact }) }), _jsx(Modal.Footer, { children: _jsx(Button, { variant: "secondary", onClick: close, children: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C" }) })] })] }));
};
export var MemoLevelUpContactButton = memo(LevelUpContactButton);
