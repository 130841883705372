import { createEvent, createStore } from 'effector';
var openModal = createEvent();
var closeModal = createEvent();
var $isModal = createStore(false)
    .on(openModal, function () { return true; })
    .on(closeModal, function () { return false; });
export var $$ModalIncomeCalculation = {
    $isModal: $isModal,
    openModal: openModal,
    closeModal: closeModal,
};
