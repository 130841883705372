import { useMemo } from 'react';
export var useApexFunnel = function (items) {
    // Memo
    var config = useMemo(function () { return ({
        series: [
            {
                name: '',
                data: items.map(function (_a) {
                    var name = _a.name, value = _a.value, color = _a.color;
                    return ({
                        x: name,
                        y: value,
                        fillColor: color,
                    });
                }),
            },
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    horizontal: true,
                    barHeight: '80%',
                    isFunnel: true,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opt) {
                    var _a, _b;
                    return ((_a = items[opt.dataPointIndex]) === null || _a === void 0 ? void 0 : _a.name) + ':  ' + ((_b = items[opt.dataPointIndex]) === null || _b === void 0 ? void 0 : _b.value);
                },
                dropShadow: {
                    enabled: true,
                },
            },
            legend: {
                show: false,
            },
        },
    }); }, [items]);
    return config;
};
