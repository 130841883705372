var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { $$app } from '../app';
import { customStrategy } from '@/shared/api';
import { GetAllFactory } from '@/shared/factories';
import { GetOneFactory } from '@/shared/factories/get-one';
import { combine, createEvent, createStore, sample } from 'effector';
//* ---------- Get All ----------
var getAll = GetAllFactory({
    name: 'CUSTOM_STRATEGY',
    api: customStrategy.getAll,
});
var $activeItemId = createStore(null);
var $activeItem = combine(getAll.$items, $activeItemId, function (items, id) {
    return (items.find(function (item) { return item.custom_strategy_id === id; }) || null);
});
var activeChanged = createEvent();
$activeItemId.on(activeChanged, function (_, value) { return value; });
sample({
    clock: $$app.started,
    target: getAll.submited,
});
sample({
    clock: getAll.done,
    filter: function (items) { return !!items.length; },
    fn: function (items) { return items[0].custom_strategy_id; },
    target: activeChanged,
});
//* ---------- Get One ----------
var getOne = GetOneFactory({
    name: 'CUSTOM_STRATEGY',
    api: customStrategy.getOne,
});
export var $$customStrategy = {
    getAll: __assign(__assign({}, getAll), { $activeItemId: $activeItemId, $activeItem: $activeItem, activeChanged: activeChanged }),
    getOne: getOne,
};
