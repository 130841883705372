var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Блок История действий
import { $$activeContactCommunications } from './model';
import './style.scss';
import { NoTableData, PageLayout } from '@/shared/components';
import { $$activeStrategy, $$communicationType } from '@/shared/effector';
import { useTableTheme } from '@/shared/hooks';
import { myMoment } from '@/shared/tools';
import { Body, Cell, Header, HeaderCell, HeaderRow, Row, Table, } from '@table-library/react-table-library';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo, useMemo } from 'react';
export var ContactWorkHistoryTable = function (_a) {
    var className = _a.className;
    // Effector
    var maxHeight = useUnit($$activeContactCommunications.$maxHeight);
    var communications = useUnit($$activeContactCommunications.$items);
    var communicationTypes = useUnit($$communicationType.getAll.$items);
    var stepResults = useUnit($$activeStrategy.$stepResults);
    var nodes = useMemo(function () {
        return communications.map(function (item) {
            var _a, _b;
            return (__assign(__assign({}, item), { id: item.communications_id, communicationType: ((_a = communicationTypes.find(function (typeItem) { return typeItem.communication_type_id === item.communication_type_id; })) === null || _a === void 0 ? void 0 : _a.name) || '', result: ((_b = stepResults.find(function (result) {
                    return result.custom_strategy_step_result_id === item.custom_strategy_step_result_id;
                })) === null || _b === void 0 ? void 0 : _b.result_name) || '' }));
        });
    }, [communicationTypes, communications, stepResults]);
    var isEmpty = !communications.length;
    var data = { nodes: nodes };
    // Hooks
    var theme = useTableTheme({
        columns: {
            base: 'repeat(4, 25%)',
            tablet: 'repeat(4, 40%)',
            mobile: 'repeat(4, 70%)',
        },
        isNoData: isEmpty,
    });
    // Variables
    var ClassName = classNames('contact-work-history-table', className);
    var style = useMemo(function () { return ({ '--max-height': "".concat(maxHeight, "px") }); }, [maxHeight]);
    return (_jsx(PageLayout.Cloud, { className: ClassName, style: style, header: { title: 'История действий' }, children: _jsx(Table, { theme: theme, layout: { custom: true, horizontalScroll: true }, data: data, children: function (items) {
                return !isEmpty ? (_jsxs(_Fragment, { children: [_jsx(Header, { children: _jsxs(HeaderRow, { children: [_jsx(HeaderCell, { children: "\u0414\u0430\u0442\u0430" }), _jsx(HeaderCell, { children: "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0435" }), _jsx(HeaderCell, { children: "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442" }), _jsx(HeaderCell, { children: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439" })] }) }), _jsx(Body, { children: items.map(function (item) { return (_jsxs(Row, { className: "clickable-row", item: item, children: [_jsx(Cell, { children: myMoment(item.date).format(myMoment.defaultViewFormat) }), _jsx(Cell, { children: item.communicationType }), _jsx(Cell, { children: item.result }), _jsx(Cell, { children: item.comment })] }, item.communications_id)); }) })] })) : (_jsx(NoTableData, {}));
            } }) }));
};
export var MemoContactWorkHistoryTable = memo(ContactWorkHistoryTable);
