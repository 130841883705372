import { finance } from '@/shared/api';
import { $$session } from '@/shared/effector';
import { attach, createEvent, createStore, sample } from 'effector';
import { $$alerts } from '@/shared/effector';
var getBalanceFx = attach({ effect: finance.getBalance });
var withdrawFx = attach({ effect: finance.withdraw });
var $balance = createStore(0);
var $value = createStore(0);
var $pending = getBalanceFx.pending;
var valueChanged = createEvent();
var submited = createEvent();
// Authed
sample({
    clock: $$session.authed,
    target: getBalanceFx,
});
$balance.on(getBalanceFx.doneData, function (_, res) { return res.balance; });
$value.on(valueChanged, function (_, payload) { return payload; });
// Submited
sample({
    clock: submited,
    source: { total: $value },
    target: withdrawFx,
});
// Submited
sample({
    clock: withdrawFx.done,
    source: $pending,
    filter: function (pending) { return !pending; },
    target: getBalanceFx,
});
sample({
    clock: withdrawFx.done,
    fn: function () { return 'Заявка на вывод создана'; },
    target: $$alerts.showSuccess,
});
sample({
    clock: withdrawFx.failData,
    fn: function () { return 'Заявка на вывод не создана'; },
    target: $$alerts.showDanger,
});
// Done
sample({
    clock: getBalanceFx.doneData,
    fn: function (_a) {
        var balance = _a.balance;
        return balance;
    },
    target: $value,
});
export var $$balance = {
    $balance: $balance,
    $value: $value,
    valueChanged: valueChanged,
    $pending: $pending,
    submited: submited,
};
