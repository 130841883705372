import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
export var ActiveStatus = function (_a) {
    var className = _a.className;
    // Variables
    var ClassName = classNames('communication-active-status', className);
    return (_jsxs("div", { className: ClassName, children: [_jsx(Skeleton, { containerClassName: "communication-active-status__indicator" }), _jsx("div", { className: "communication-active-status__name", children: _jsx(Skeleton, { width: 200 }) })] }));
};
export var SkeletonActiveStatus = memo(ActiveStatus);
