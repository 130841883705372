var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { $$app } from '../app';
import { globalStrategyStatusColor } from '@/shared/api';
import { GetAllFactory } from '@/shared/factories';
import { sample } from 'effector';
//* Get All
var getAll = GetAllFactory({
    name: 'GLOBAL_STRATEGY_STATUS_COLOR',
    api: globalStrategyStatusColor.getAll,
});
var $dictionaryById = getAll.$items.map(function (colors) {
    return colors.reduce(function (t, c) {
        var _a;
        return (__assign(__assign({}, t), (_a = {}, _a[c.global_strategy_status_color_id] = c.color_HEX, _a)));
    }, {});
});
sample({
    clock: $$app.started,
    target: getAll.submited,
});
export var $$globalStrategyStatusColor = { getAll: __assign(__assign({}, getAll), { $dictionaryById: $dictionaryById }) };
