import { jsx as _jsx } from "react/jsx-runtime";
import BlobWebpBlue from './blob2.webp';
import BlobWebpBlack from './gifs/gif-black-opt.webp';
import BlobWebpWhite from './gifs/gif-white-opt.webp';
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var Blob = function (_a) {
    // Variables
    var className = _a.className, _b = _a.image, image = _b === void 0 ? 'BlobWebpBlue' : _b;
    var ClassName = classNames('my-blob', className);
    return (_jsx("div", { className: ClassName, children: _jsx("img", { src: image === 'BlobWebpBlue'
                ? BlobWebpBlue
                : image === 'BlobWebpWhite'
                    ? BlobWebpWhite
                    : BlobWebpBlack, alt: "blob", width: "120px", height: "120px" }) }));
};
export var MemoBlob = memo(Blob);
