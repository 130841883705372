import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import { Form } from '@/shared/components';
import { $$activeStrategy, $$customStrategies } from '@/shared/effector';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo } from 'react';
export var ActiveStrategySelect = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? 'sm' : _b;
    // Effector
    var _c = useUnit([
        $$customStrategies.$items,
        $$activeStrategy.$activeStrategyId,
        $$activeStrategy.activeStrategyChanged,
    ]), strategies = _c[0], strategyId = _c[1], strategyChanged = _c[2];
    // Variables
    var ClassName = classNames('active-strategy-select', className);
    var strategiesForSelect = strategies.map(function (strategy) { return ({
        value: strategy.custom_strategy_id,
        text: strategy.name,
    }); });
    if (!strategyId) {
        return null;
    }
    return (_jsx(Form.Select, { className: ClassName, size: size, items: strategiesForSelect, value: strategyId, onChange: strategyChanged }));
};
export var MemoActiveStrategySelect = memo(ActiveStrategySelect);
