import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { Avatar, MemoSkeletonButton, PageLayout } from '@/shared/components';
import classNames from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
export var SkeletonContactCloud = function (_a) {
    var title = _a.title, subtitle = _a.subtitle;
    // Refs
    var elementRef = useRef(null);
    // State
    var _b = useState(0), elementWidth = _b[0], setElementWidth = _b[1];
    // Variables
    var ClassName = classNames('contact-cloud', {
        'contact-cloud--column': elementWidth <= 560 && elementWidth !== 0,
    });
    // Effects
    useLayoutEffect(function () {
        if (!elementRef.current) {
            return;
        }
        var handleResize = function () {
            var _a, _b;
            setElementWidth((_b = (_a = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 0);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return (_jsxs(PageLayout.Cloud, { elementRef: elementRef, contentClassName: ClassName, header: {
            title: title || _jsx(Skeleton, { width: 200 }),
            subtitle: subtitle || _jsx(Skeleton, { width: 120 }),
        }, children: [_jsxs("div", { className: "contact-cloud__row", children: [_jsxs("div", { className: "contact-cloud__content", children: [_jsx("div", { className: "contact-cloud__avatar", children: _jsx(Avatar, { size: 100, isSkeleton: true }) }), _jsxs("div", { className: "contact-cloud__info", children: [_jsx(Skeleton, { className: "contact-cloud__name", width: 90 }), _jsx("div", { className: "contact-cloud__strategy" }), _jsx(Skeleton, { className: "contact-cloud__status", width: 120 })] })] }), _jsx("div", { className: "contact-cloud__speed-test", children: _jsx(Skeleton, { width: 265, height: 152, borderRadius: 32 }) })] }), _jsx("div", { className: "contact-cloud__row", children: _jsxs("div", { className: "contact-cloud__buttons", children: [_jsx(MemoSkeletonButton, {}), _jsx(MemoSkeletonButton, {}), _jsx(MemoSkeletonButton, {})] }) })] }));
};
