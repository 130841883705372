import { routes } from '../router';
import { $$loginForm } from './auth/login-form';
import { profile } from '@/shared/api';
import { $$app } from '@/shared/effector/app';
import { redirect } from 'atomic-router';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import { $$lotteryTickets } from './lottery-tickets/lottery-tickets';
var getFx = attach({ effect: profile.get });
var getLoginFx = attach({ effect: profile.getLogin });
export var Auth;
(function (Auth) {
    Auth[Auth["Unknown"] = 0] = "Unknown";
    Auth[Auth["Anonymous"] = 1] = "Anonymous";
    Auth[Auth["Authorized"] = 2] = "Authorized";
})(Auth || (Auth = {}));
var $auth = createStore(Auth.Unknown);
var $login = createStore(null);
var $profile = createStore(null);
var $refLogin = createStore('PRM4ALL');
var refLoginChanged = createEvent();
var authed = createEvent();
var logout = createEvent();
var logouted = createEvent();
var checkLocalStorageFx = createEffect(function () {
    var _a;
    var isAuthText = (_a = localStorage.getItem('is-auth')) !== null && _a !== void 0 ? _a : 'false';
    return isAuthText === 'true' ? true : false;
});
var logoutFx = createEffect(function () {
    localStorage.setItem('is-auth', 'false');
});
$refLogin.on(refLoginChanged, function (_, value) { return value; });
var reload = createEvent();
sample({
    clock: [reload],
    target: getFx,
});
// Started
sample({
    clock: $$app.started,
    target: checkLocalStorageFx,
});
// Checked
sample({
    clock: checkLocalStorageFx.doneData,
    filter: function (isAuth) { return isAuth; },
    target: getFx,
});
sample({
    clock: checkLocalStorageFx.doneData,
    filter: function (isAuth) { return isAuth; },
    target: getLoginFx,
});
sample({
    clock: checkLocalStorageFx.doneData,
    filter: function (isAuth) { return !isAuth; },
    fn: function () { return Auth.Anonymous; },
    target: $auth,
});
// Logined
sample({
    clock: $$loginForm.done,
    fn: function () { return Auth.Unknown; },
    target: $auth,
});
sample({
    clock: $$loginForm.done,
    target: getFx,
});
sample({
    clock: $$loginForm.done,
    target: getLoginFx,
});
// Done
sample({
    clock: getFx.done,
    fn: function () { return Auth.Authorized; },
    target: $auth,
});
sample({
    clock: getFx.done,
    target: authed,
});
sample({
    clock: getFx.doneData,
    target: $profile,
});
sample({
    clock: getLoginFx.doneData,
    fn: function (response) { return response.login; },
    target: $login,
});
sample({
    clock: getFx.done,
    target: $$lotteryTickets.getTicketsFx
});
// Fail
sample({
    clock: getFx.fail,
    fn: function () { return Auth.Anonymous; },
    target: $auth,
});
sample({
    clock: logout,
    target: logoutFx,
});
// Logout
sample({
    clock: logout,
    fn: function () { return Auth.Anonymous; },
    target: $auth,
});
sample({
    clock: logout,
    target: logoutFx,
});
redirect({
    clock: logoutFx.done,
    route: routes.account.main,
});
$login.on(logout, function () { return null; });
$profile.on(logout, function () { return null; });
export var $$session = {
    $auth: $auth,
    $login: $login,
    $profile: $profile,
    $refLogin: $refLogin,
    refLoginChanged: refLoginChanged,
    authed: authed,
    logout: logout,
    logouted: logouted,
    reload: reload,
};
