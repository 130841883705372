import { createEvent, createStore } from "effector";
var $currentAccount = createStore(null);
var currentAccountChanged = createEvent();
$currentAccount.on(currentAccountChanged, function (_, value) { return value; });
// sample({
//     clock: $$session.authed,
//     filter: () => !!window.ethereum === false,
//     fn: () => 'MetaMask is not installed',
//     target: $$alerts.showDanger
// })
// sample({
//     clock: $$session.authed,
//     filter: () => !!window.ethereum === true,
//     fn: () => `Метамакс: подключен адрес ${$currentAccount}`,
//     target: $$alerts.showSuccess
// })
export var $$metamask = {
    $currentAccount: $currentAccount,
    currentAccountChanged: currentAccountChanged
};
