import { jsx as _jsx } from "react/jsx-runtime";
import './style.scss';
import classNames from 'classnames';
import { memo } from 'react';
export var Footer = function (_a) {
    var className = _a.className;
    // Variables
    var ClassName = classNames('auth-layout-footer', className);
    return _jsx("div", { className: ClassName, children: "\u00A9 2024 PRM4ALL. All Rights Reserved." });
};
export var MemoFooter = memo(Footer);
