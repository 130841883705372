import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MemoActivityPrm, MemoCopyInviteLinkButton, PageTitle } from '../shared';
import Metamask from './Metamask';
import './style.scss';
import { InfoHeaderIcon, NotificationsHeaderIcon, SidebarToggle, ThemeHeaderIcon, } from '@/shared/assets';
import { Avatar } from '@/shared/components';
import { $$session } from '@/shared/effector';
import { $$lotteryTickets } from '@/shared/effector/lottery-tickets/lottery-tickets';
import { openModal } from '@/shared/effector/lottery-tickets/modal-status';
import { useSidebar } from '@/shared/hooks';
import { routes } from '@/shared/router';
import { useTheme } from '@/shared/theme';
import { Link } from 'atomic-router-react';
import { useUnit } from 'effector-react';
export var Header = function () {
    // Hooks
    var open = useSidebar().open;
    var toggle = useTheme().toggle;
    var openLotteryModal = useUnit([openModal])[0];
    var tickets = useUnit([$$lotteryTickets.$tickets])[0]; // сейчас возвращает пустой массив
    // const tickets = ['билет 1', 'билет 2', 'билет 3']
    var profile = useUnit([$$session.$profile])[0];
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "header-space", children: _jsx(PageTitle, { className: "header-space__title" }) }), _jsx("div", { className: "header", children: _jsxs("div", { className: "header__content", children: [_jsxs("div", { className: "header__left", children: [_jsx("div", { className: "header__sidebar-toggle", onClick: open, children: _jsx(SidebarToggle, { width: 36, height: 36 }) }), _jsx(PageTitle, { className: "header__title" }), _jsx(MemoActivityPrm, { className: "header__activity" })] }), _jsxs("div", { className: "header__right", children: [_jsx(MemoCopyInviteLinkButton, { className: "header__ref-link" }), _jsx(Metamask, {}), _jsxs("div", { className: "header__icon header-icon ".concat(tickets.length ? 'header-icon--clickable' : ''), onClick: openLotteryModal, children: [tickets.length ? _jsx("div", { className: "notification-lottery-letters" }) : null, _jsx(NotificationsHeaderIcon, {})] }), _jsx("div", { className: "header__icon header-icon header-icon--clickable", onClick: toggle, children: _jsx(ThemeHeaderIcon, {}) }), _jsx("div", { className: "header__icon header-icon", children: _jsx(InfoHeaderIcon, {}) }), _jsx(Link, { to: routes.account.profile, children: (profile === null || profile === void 0 ? void 0 : profile.avatar) ? (_jsx("img", { alt: profile.first_name, src: "https://backmoon.prm4all.com/".concat(profile === null || profile === void 0 ? void 0 : profile.avatar), className: "header__avatar" })) : (_jsx(Avatar, { className: "header__avatar" })) })] })] }) })] }));
};
