import { $$session, Auth } from '@/shared/effector';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
export var useAuthorized = function (needRedirectToLanding) {
    if (needRedirectToLanding === void 0) { needRedirectToLanding = false; }
    // Effector
    var auth = useUnit($$session.$auth);
    // Variables
    var authing = auth === Auth.Unknown;
    var isAuthorized = auth === Auth.Authorized;
    var isAnonymous = auth === Auth.Anonymous;
    // Effect
    useEffect(function () {
        if (!needRedirectToLanding) {
            return;
        }
        if (authing) {
            return;
        }
        if (isAnonymous) {
            location.pathname = '/';
        }
    }, [authing, isAnonymous, needRedirectToLanding]);
    return {
        authing: authing,
        isAuthorized: isAuthorized,
        isAnonymous: isAnonymous,
    };
};
