var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { $$customStrategy } from './strategy';
import { customStrategyStepResult } from '@/shared/api/strategy/custom-strategy/strategy-step-result';
import { GetAllFactory } from '@/shared/factories';
import { createStore, sample } from 'effector';
var strategies = $$customStrategy.getAll;
//* ---------- Get All Of Strategies ----------
var getAll = GetAllFactory({
    name: 'CUSTOM_STRATEGY_STEP_RESULT',
    api: customStrategyStepResult.getAllOfStrategies,
});
var $linkedToStrategiesItems = createStore({});
// Strategies Done
sample({
    clock: strategies.done,
    fn: function (strategies) { return ({ ids: strategies.map(function (strategy) { return strategy.custom_strategy_id; }) }); },
    target: getAll.submited,
});
// Done
sample({
    clock: getAll.done,
    source: getAll.$params,
    fn: function (params, results) {
        return params.ids.reduce(function (t, c, index) {
            if (!results[index].length) {
                return;
            }
            t[c] = results[index];
            return t;
        }, {});
    },
});
export var $$customStrategyStepResult = {
    getAllOfStrategies: __assign({ $linkedToStrategiesItems: $linkedToStrategiesItems }, getAll),
};
