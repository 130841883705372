import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.scss';
import { ChevronLeftIcon } from '@/shared/assets';
import { routes } from '@/shared/router';
import { Link } from 'atomic-router-react';
import classNames from 'classnames';
import { memo } from 'react';
export var GoBackButton = function (_a) {
    var className = _a.className;
    // Variables
    var ClassName = classNames('go-back-button', className);
    return (_jsxs(Link, { to: routes.landing, className: ClassName, children: [_jsx(ChevronLeftIcon, { width: 24, height: 24 }), _jsx("div", { className: "go-back-button__text", children: "\u041D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E" })] }));
};
export var MemoGoBackButton = memo(GoBackButton);
