import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { AlertItem } from './components/alert-item';
import './style.scss';
import { $$alerts } from '@/shared/effector';
import { useUnit } from 'effector-react';
import ReactDOM from 'react-dom';
var modalsNode = document.getElementById('modals');
export var Alerts = function () {
    // Effector
    var items = useUnit($$alerts.$items);
    var hidden = useUnit($$alerts.hidden);
    if (!modalsNode) {
        return null;
    }
    return (_jsx(_Fragment, { children: ReactDOM.createPortal(_jsx("div", { className: "alerts", children: items.map(function (_a) {
                var id = _a.id, message = _a.message, variant = _a.variant;
                return (_jsx("div", { className: "alerts__item", children: _jsx(AlertItem, { id: id, message: message, variant: variant, onClose: hidden }) }, id));
            }) }), modalsNode) }));
};
