import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { $$resetStrategyButton } from './model';
import './style.scss';
import { MemoButton } from '@/shared/components';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { memo, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
export var ResetStrategyButton = function (_a) {
    var className = _a.className;
    // Effector
    var submited = useUnit($$resetStrategyButton.submited);
    // States
    var _b = useState(false), show = _b[0], setShow = _b[1];
    // Variables
    var ClassName = classNames('reset-strategy-button', className);
    // Handlers
    var handleSubmit = useCallback(function () {
        setShow(false);
        submited();
    }, [submited]);
    return (_jsxs(_Fragment, { children: [_jsx(MemoButton, { type: "button", className: ClassName, variant: "danger", onClick: function () { return setShow(true); }, children: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C" }), _jsxs(Modal, { show: show, onHide: function () { return setShow(false); }, children: [_jsx(Modal.Header, { children: _jsx(Modal.Title, { children: "\u0421\u0431\u0440\u043E\u0441 \u0441\u0442\u0440\u0430\u0442\u0435\u0433\u0438\u0438" }) }), _jsxs(Modal.Body, { children: ["\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0441\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u0441\u0442\u0440\u0430\u0442\u0435\u0433\u0438\u044E? ", _jsx("br", {}), "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u043C \u0431\u0443\u0434\u0435\u0442 \u0431\u0435\u0437\u0432\u043E\u0437\u0432\u0440\u0430\u0442\u043D\u044B\u0439 \u043E\u0442\u043A\u0430\u0442 \u043A \u043F\u0435\u0440\u0432\u043E\u043D\u0430\u0447\u0430\u043B\u044C\u043D\u043E\u043C\u0443 \u0432\u0438\u0434\u0443"] }), _jsxs(Modal.Footer, { children: [_jsx(MemoButton, { onClick: handleSubmit, children: "\u0414\u0430" }), _jsx(MemoButton, { variant: "secondary", onClick: function () { return setShow(false); }, children: "\u041D\u0435\u0442" })] })] })] }));
};
export var MemoResetStrategyButton = memo(ResetStrategyButton);
