import { $$activeStrategy, $$globalStrategyStatusColor } from '@/shared/effector';
import { $$contactCustomStrategyStatuses } from '@/shared/effector/contact';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';
export var useFunnelStatuses = function (_a) {
    var _b = _a.defaultColor, defaultColor = _b === void 0 ? '#000000' : _b;
    // Effector
    var statuses = useUnit($$activeStrategy.$statuses);
    var colors = useUnit($$globalStrategyStatusColor.getAll.$items);
    var countContactsInStatuses = useUnit($$contactCustomStrategyStatuses.$countContactsInStatuses);
    var requestStatuses = useUnit([
        $$activeStrategy.$statusesStatus,
        $$globalStrategyStatusColor.getAll.$status,
        $$contactCustomStrategyStatuses.$status,
    ]);
    // Memo
    var items = useMemo(function () {
        return statuses.map(function (status) {
            var _a;
            return ({
                name: status.name,
                value: countContactsInStatuses[status.custom_strategy_status_id] || 0,
                color: ((_a = colors.find(function (color) {
                    return color.global_strategy_status_color_id === status.global_strategy_status_color_id;
                })) === null || _a === void 0 ? void 0 : _a.color_HEX) || defaultColor,
            });
        });
    }, [colors, countContactsInStatuses, defaultColor, statuses]);
    // Variables
    var pending = requestStatuses.some(function (status) { return status === 'pending'; });
    return { items: items, pending: pending };
};
