import { currentRoute } from './model';
import { suspenseWrapper } from '@/pages/suspense-wrapper';
import { PageLayout } from '@/shared/components';
import { lazy } from 'react';
import { chainAdmin } from '@/shared/effector/administration/admin-status';
export var Page = suspenseWrapper(lazy(function () { return import('./ui'); }));
export var AdministrationRoute = {
    view: Page,
    route: chainAdmin(currentRoute),
    // route: currentRoute,
    layout: PageLayout,
};
